import {
  userBackupDetailPath,
  organizationInformationPath,
  resellersListPath,
} from '@config/Routes/WebClientRoutes';

export const EMAIL = 'email';
export const EMAIL_TEXT = 'Email Backup';
export const EMAIL_COLUMNS = [
  { label: 'ID', columnName: 'userBackupId' },
  {
    label: 'Email',
    columnName: 'email',
    size: 'sm',
    navigateURL: (row) =>
      `${userBackupDetailPath}?id=${row.userBackupId}&email=${row.email}&backupType=${EMAIL}`,
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
    navigateURL: (row) =>
      `${organizationInformationPath}?id=${row.organizationId}&name=${row.organizationName}`,
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
    navigateURL: (row) => `${resellersListPath}?id=${row.partnerId}&name=${row.partnerName}`,
  },
  {
    label: 'Parent Name',
    columnName: 'distributorName',
    size: 'sm',
    navigateURL: (row) =>
      `${resellersListPath}?id=${row.distributorId}&name=${row.distributorName}`,
  },
  { label: 'Protocol', columnName: 'protocol', size: 'sm' },
  { label: 'Last Backup At', columnName: 'lastBackupAt', size: 'sm' },
  { label: 'Last Backup Status', columnName: 'lastBackupStatus', size: 'sm' },
  {
    label: 'Last Backup Successful At',
    columnName: 'lastBackupSuccessfulAt',
    size: 'sm',
  },
  { label: 'Next Backup At', columnName: 'nextBackupAt', size: 'sm' },
  { label: 'M365 ID / GWS ID', columnName: 'm365Id', size: 'sm' },
  { label: 'Tenant ID', columnName: 'tenantId', size: 'sm' },
  { label: 'upn', columnName: 'upn', size: 'sm' },
  { label: 'Mailbox Name', columnName: 'mailboxName', size: 'sm' },
  { label: 'Has CCT', columnName: 'hasCCT', size: 'sm' },
  { label: 'Message Number', columnName: 'messageCount', size: 'sm' },
  { label: 'Storaged Used in KB', columnName: 'storageUsed', size: 'sm' },
  { label: 'Setup Completed', columnName: 'completedSetup', size: 'sm' },
  { label: 'Journal Setup Completed', columnName: 'journalSetupCompleted', size: 'sm' },
  { label: 'Retention Period in Days', columnName: 'retentionPeriod' },
  { label: 'Has Legal Hold', columnName: 'hasLegalHold', size: 'sm' },
  { label: 'Is Shared Mailbox', columnName: 'isSharedMailbox', size: 'sm' },
  {
    label: 'M365 In Place Archive Status',
    columnName: 'm365InPlaceArchiveStatus',
  },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
  { label: 'Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Deleted At', columnName: 'deletedAt', size: 'sm' },
  { label: 'Deleted By', columnName: 'deletedBy', size: 'sm' },
  { label: 'Data Deleted At', columnName: 'dataDeletedAt', size: 'sm' },
];
export const EMAIL_DETAIL_ROWS = {
  userBackupId: 'Email Account ID',
  email: 'Email',
  username: 'Username',
  upn: 'UPN',
  mailboxName: 'Mailbox Name',
  organizationName: 'Organization Name',
  partnerName: 'Partner Name',
  distributorName: 'Parent Name',
  host: 'Host',
  startTLS: 'Start TLS',
  ssl: 'SSL',
  port: 'Port',
  protocol: 'Protocol',
  lastBackupId: 'Last Backup ID',
  lastBackupAt: 'Last Backup At',
  lastBackupSuccessfulAt: 'Last Successful Backup At',
  nextBackupAt: 'Next Backup At',
  hasCCT: 'Has CCT',
  messageCount: 'Message Number',
  storageUsed: 'Storaged Used in KB',
  deactivatedSince: 'Deactivated Since',
  deactivatedBy: 'Deactivated By',
  deletedAt: 'Deleted At',
  deletedBy: 'Deleted By',
  dataDeletedAt: 'Data Deleted At',
  createdAt: 'Created At',
  updatedAt: 'Last Data Updated At',
  completedSetup: 'Setup Completed',
  retentionId: 'Retention ID',
  retentionPeriod: 'Retention Period in Days',
  hasLegalHold: 'Has Legal Hold',
  oauthType: 'Oauth Type',
  isSharedMailbox: 'Is Shared Mailbox',
  m365InPlaceArchiveStatus: 'M365 In Place Archive Status',
  testMessage: 'Test Connection Messasge',
  testStatus: 'Test Connection Status',
  apsCustomerId: 'APS Customer ID',
  apsId: 'APS ID',
  journalId: 'Journal ID',
  lastBackupSuccessSentAt: 'Last Notification Success Backup Sent At',
  lastBackupFailedSentAt: 'Last Notification Backup Failed Sent At  ',
  deactivatedNotificationSentAt: 'Last Notification Deactivated Sent At',
  fullArchiveData: 'Full Archive Data',
  exchangeAdminMailboxId: 'Exchange Admin Mailbox (for Hex Only)',
  m365LicenseReport: 'License Checker Attributes',
  shaSharedMailboxLogin: 'sha SharedMailbox Login',
  m365Id: 'M365 ID / GWS ID',
  tenantId: 'Tenant ID',
  externalMailboxUId: 'External Mailbox UID',
  generatedName: 'Mailbox Display Name',
  publicFolder: 'Public Folder',
  dmeLicensed: 'DME Licensed',
  journalSetupCompleted: 'Journal Setup Completed',
  logChangeEmail: 'Change Email Log Through API',
  publicFolderPermissions: 'Public Folder Permissions',
  addFromOtherMailbox: 'Add From Other Mailbix (IONOS Only)',
  nextArchiveFolderSyncAt: 'Next Archive Folder Sync At',
  pollingStatus: 'Polling Status',
  pollingErrorMessage: 'Polling Error Message',
  privateChatActive: 'Private Chat Active',
  addByAutodiscover: 'Added by Autodiscover',
  lastDownloadedSnapshotAt: 'Last Download Snapshot At',
  lastArchivedAt: 'Last Archive With Messages At',
};
export const EMAIL_LAST_BACKUP_ROWS = {
  backupId: 'Last Email Backup ID',
  userBackupId: 'Email Account ID',
  organizationId: 'Organization ID',
  startTime: 'Start Backup Time',
  endTime: 'End Backup Time',
  status: 'Backup Status',
  backupStorage: 'Backup Storage KB',
  compressedStorage: 'Compressed Storage',
  currentMessagesCount: 'Current Message Numbers',
  totalMessagesCount: 'Total Message Numbers',
  retries: 'Retries Time',
  errorMessage: 'Error Message',
  skipMessages: 'Skip Messages',
  oldDMEId: 'Old DME ID',
  lastXMLResponse: 'XML Response',
  machineIP: 'Run on Machine IP',
  createdAt: 'Created At',
  updatedAt: 'Last Updated At',
};
