import {
  userBackupDetailPath,
  organizationInformationPath,
  resellersListPath,
} from '@config/Routes/WebClientRoutes';

export const ENTRA_ID = 'entraid';
export const ENTRA_ID_TEXT = 'Entra';
export const ENTRA_ID_COLUMNS = [
  { label: 'ID', columnName: 'userBackupId' },
  {
    label: 'Name',
    columnName: 'backupName',
    size: 'sm',
    navigateURL: (row) =>
      `${userBackupDetailPath}?id=${row.userBackupId}&email=${row.backupName}&backupType=${ENTRA_ID}`,
  },
  {
    label: 'Initial Domain',
    columnName: 'domainName',
    size: 'sm',
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
    navigateURL: (row) =>
      `${organizationInformationPath}?id=${row.organizationId}&name=${row.organizationName}`,
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
    navigateURL: (row) => `${resellersListPath}?id=${row.partnerId}&name=${row.partnerName}`,
  },
  { label: 'Tenant ID', columnName: 'tenantId', size: 'sm' },
  { label: 'Object Count', columnName: 'objectCount', size: 'sm' },
  { label: 'Country', columnName: 'country', size: 'sm' },
  { label: 'Permission Status', columnName: 'permissionStatus', size: 'sm' },
  { label: 'Setup Completed', columnName: 'completedSetup', size: 'sm' },
  { label: 'Backup Frequency', columnName: 'backupFrequency', size: 'sm' },
  { label: 'Last Backup Status', columnName: 'lastBackupStatus', size: 'sm' },
  {
    label: 'Last Successful Backup At',
    columnName: 'lastBackupSuccessAt',
    size: 'sm',
  },
  { label: 'Next Backup At', columnName: 'nextBackupAt', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
  { label: 'Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Deleted At', columnName: 'deletedAt', size: 'sm' },
];
export const ENTRA_ID_DETAIL_ROWS = {
  userBackupId: 'Entra ID',
  backupName: 'Name',
  domainName: 'Initial Domain',
  organizationName: 'Organization Name',
  partnerName: 'Partner Name',
  tenantId: 'Tenant ID',
  objectCount: 'Object Count',
  country: 'Country',
  permissionStatus: 'Permission Status',
  completedSetup: 'Setup Completed',
  backupFrequency: 'Backup Frequency',
  lastBackupId: 'Last Backup ID',
  lastBackupStatus: 'Last Backup Status',
  lastBackupSuccessfulAt: 'Last Successful Backup At',
  nextBackupAt: 'Next Backup At',
  deactivatedSince: 'Deactivated Since',
  deactivatedBy: 'Deactivated By',
  deletedAt: 'Deleted At',
  createdAt: 'Created At',
  updatedAt: 'Last Data Updated At',
};
export const ENTRA_ID_LAST_BACKUP_ROWS = {
  backupId: 'Last Entra Backup ID',
  userBackupId: 'Entra ID',
  startTime: 'Start Backup Time',
  endTime: 'End Backup Time',
  status: 'Backup Status',
  totalObjectCount: 'Total Number of Object',
  currentObjectCount: 'Current Number of Object',
  retries: 'Retries Time',
  errorMessage: 'Error Message',
  createdAt: 'Created At',
  updatedAt: 'Last Updated At',
};
