import * as yup from 'yup';
import useForm from '@hooks/useForm';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { useRemoveMailboxFromExclusionMutation } from '@services/WebApiService/MailboxApi';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showFixedAlert } from '@features/application/applicationSlice';
import ClientRoutes from '@config/Routes/WebClientRoutes';

const formSchema = yup.object().shape({
  supportTicket: yup.string().required('Support Ticket is required').default(''),
  escalationTicket: yup.string().default(''),
  description: yup.string().default(''),
});

export default function useRemoveMailboxFromExclusion({
  organizationOwnerId,
  email,
  onSuccessRequest,
  onFailedRequest,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const form = useForm(formSchema);
  const [removeMailboxFromExclusion, removeMailboxFromExclusionState] =
    useRemoveMailboxFromExclusionMutation();

  useEffect(() => {
    if (!form.isValid) return;
    removeMailboxFromExclusion({
      accessToken: accessTokenSet,
      params: {
        'support-ticket': form.values.supportTicket,
        description: form.values.description,
        'escalation-ticket': form.values.escalationTicket,
        'org-owner-id': organizationOwnerId,
        email,
      },
    });
  }, [form.isValid]);

  const resetAll = () => {
    removeMailboxFromExclusionState.reset();
    form.reset();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    form.validate();
  };

  useEffect(() => {
    if (removeMailboxFromExclusionState.data?.['status-code'] === 200) {
      if (removeMailboxFromExclusionState.data?.data?.serviceTypeCode) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': removeMailboxFromExclusionState.data?.data?.serviceTypeCode,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t('remove_mailbox_from_exclusion.success_submitted_with_link', {
              link,
            }),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('remove_mailbox_from_exclusion.success_submitted'),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
      resetAll();
    } else if (removeMailboxFromExclusionState.error && onFailedRequest) {
      onFailedRequest(
        removeMailboxFromExclusionState.error?.data?.message ||
          removeMailboxFromExclusionState.error?.data?.['status-code'] ||
          removeMailboxFromExclusionState.error?.status ||
          (removeMailboxFromExclusionState.error.name === 'FetchError'
            ? 'Network error occurred'
            : 'Unknown error occurred')
      );
      form.reset();
    }
  }, [removeMailboxFromExclusionState.data, removeMailboxFromExclusionState.error]);

  return {
    fetching: removeMailboxFromExclusionState.isLoading,
    removeMailboxFromExclusionState,
    form,
    handleSubmit,
    resetAll,
  };
}
