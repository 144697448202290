import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  ITBox,
  QboAlert,
  QboCheckbox,
  QboFormGroup,
  QboSelect,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import useEmailFromCSV from '@hooks/useEmailFromCSV';
import { resetCreateSwitchCredentialState } from '@features/switchCredential/createSwitchCredentialSlice';

export default function SwitchCredentialFormSection({
  orgOwnerIdAttributes,
  mappingFileAttributes,
  supportTicketAttributes,
  escalationTicketAttributes,
  emailIdsAttributes,
  descriptionAttributes,
  onSubmit,
  resetForm,
  formData,
  changeRequestTypeAttributes,
  fromCredentialIdAttributes,
  toCredentialIdAttributes,
  submitting,
  switchTypeOptions,
}) {
  const { t } = useTranslation();
  const [emailLists, setEmailLists] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { handleOnFileUpload } = useEmailFromCSV();
  const dispatch = useDispatch();
  const isFormEmpty = Object.values(formData).filter((i) => i).length === 0;
  const isAstericRequired = true;
  const inputRef = useRef(null);

  const { createSwitchCredentialState } = useSelector((state) => state.createSwitchCredential);
  const { statusCode, errorMessage } = createSwitchCredentialState;

  useEffect(() => {
    if (emailLists.length > 0) {
      emailIdsAttributes.handleChange(emailLists.toString());
    }
  }, [emailLists]);

  useEffect(() => {
    if (statusCode === 200 || statusCode === 401) {
      inputRef.current.value = null;
      resetForm();
    }
  }, [statusCode]);

  useEffect(() => {
    if (statusCode === 200 || statusCode === 401) {
      if (!isFormEmpty) dispatch(resetCreateSwitchCredentialState());
    }
  }, [isFormEmpty]);

  const showFromCredentialField = !['1', '5'].includes(changeRequestTypeAttributes.value);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup
        onSubmit={onSubmit}
        loadingButton={submitting}
        buttonDisabled={submitting || buttonDisabled}
        withSubmitButton>
        {(statusCode || errorMessage) && statusCode !== 401 && (
          <QboAlert type={statusCode === 200 ? 'success' : 'error'} style={{ fontSize: '1.15em' }}>
            {(statusCode === 200 && 'Successfully Submitted') || errorMessage}
          </QboAlert>
        )}
        <QboAlert
          variant="outlined"
          type="info"
          style={{
            border: '1px solid #ebebeb',
            background: '#f3f3f3',
            fontSize: '10px !important',
            marginTop: '20px',
          }}>
          <QboTypography noWrap={false} style={{ fontSize: '13px', color: '#767676' }}>
            {t('switch_credential.labels.urgent_information')}
          </QboTypography>
        </QboAlert>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <QboTextField
              id="supportTicket"
              required={isAstericRequired}
              placeholder={t('switch_credential.labels.support_ticket')}
              label={t('switch_credential.labels.support_ticket')}
              fullWidth
              value={supportTicketAttributes.value}
              errorMessage={supportTicketAttributes.errorMessage}
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                supportTicketAttributes.handleChange(e.currentTarget.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <QboTextField
              id="escalationTicket"
              placeholder={t('switch_credential.labels.escalation_ticket')}
              label={t('switch_credential.labels.escalation_ticket')}
              fullWidth
              value={escalationTicketAttributes.value}
              onChange={(e) => escalationTicketAttributes.handleChange(e.currentTarget.value)}
              inputProps={{ maxLength: 225 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <QboTextField
              id="description"
              placeholder={t('switch_credential.labels.description')}
              label={t('switch_credential.labels.description')}
              fullWidth
              value={descriptionAttributes.value}
              onChange={(e) => descriptionAttributes.handleChange(e.currentTarget.value)}
              inputProps={{ maxLength: 225 }}
            />
          </Grid>
          <Grid item xs={6}>
            <QboTextField
              id="organizationOwnerId"
              required={isAstericRequired}
              placeholder={t('switch_credential.labels.organization_owner_id')}
              label={t('switch_credential.labels.organization_owner_id')}
              fullWidth
              inputProps={{ maxLength: 225 }}
              value={orgOwnerIdAttributes.value}
              onChange={(e) => {
                orgOwnerIdAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={orgOwnerIdAttributes.errorMessage}
              type="number"
            />
          </Grid>
        </Grid>

        <QboSelect
          label="Select switch type"
          options={switchTypeOptions}
          handleChange={(e) => {
            changeRequestTypeAttributes.handleChange(e.target.value);
          }}
          value={changeRequestTypeAttributes?.value}
          defaultValue="1"
        />

        <Grid container spacing={showFromCredentialField ? 2 : 1}>
          {showFromCredentialField && (
            <Grid item xs={6}>
              <QboTextField
                id="from-credential-id"
                placeholder="From Credential Id"
                label="From Credential Id"
                fullWidth
                value={fromCredentialIdAttributes.value}
                onChange={(e) => fromCredentialIdAttributes.handleChange(e.currentTarget.value)}
                inputProps={{ maxLength: 225 }}
                errorMessage={fromCredentialIdAttributes.errorMessage}
                required={isAstericRequired}
                type="number"
              />
            </Grid>
          )}
          <Grid item xs={showFromCredentialField ? 6 : 12}>
            <QboTextField
              id="to-credential-id"
              placeholder="Target Credential Id"
              label="Target Credential Id"
              fullWidth
              value={toCredentialIdAttributes.value}
              onChange={(e) => toCredentialIdAttributes.handleChange(e.currentTarget.value)}
              inputProps={{ maxLength: 225 }}
              errorMessage={toCredentialIdAttributes.errorMessage}
              required={isAstericRequired}
              type="number"
            />
          </Grid>
        </Grid>

        <QboTextField
          id="emailIds"
          required={isAstericRequired}
          placeholder={t('switch_credential.labels.email_accounts')}
          label={t('switch_credential.labels.email_accounts')}
          multiline={Boolean(true)}
          rows={5}
          fullWidth
          value={emailIdsAttributes.value}
          onChange={(e) => emailIdsAttributes.handleChange(e.currentTarget.value)}
          errorMessage={emailIdsAttributes.errorMessage}
        />
        <QboTextField
          id="csvFile"
          placeholder={t('switch_credential.labels.csv_file')}
          label={t('switch_credential.labels.csv_file')}
          type="file"
          inputRef={inputRef}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={mappingFileAttributes?.value?.file}
          onChange={(event) => {
            handleOnFileUpload(event, setEmailLists);
            mappingFileAttributes.handleChange(event?.target?.files[0]);
          }}
          errorMessage={mappingFileAttributes.errorMessage}
        />
        <QboCheckbox
          sx={{ fontSize: '12px !important' }}
          className="QboCheckbox__wrapper_text"
          label={t('switch_credential.labels.confirming_the_above_information')}
          checked={!buttonDisabled}
          onChange={(e) => {
            setButtonDisabled(!e.target.checked);
          }}
        />
      </QboFormGroup>
    </ITBox>
  );
}
SwitchCredentialFormSection.propTypes = {
  orgOwnerIdAttributes: PropTypes.object,
  mappingFileAttributes: PropTypes.object,
  supportTicketAttributes: PropTypes.object,
  escalationTicketAttributes: PropTypes.object,
  emailIdsAttributes: PropTypes.object,
  descriptionAttributes: PropTypes.object,
  changeRequestTypeAttributes: PropTypes.object,
  fromCredentialIdAttributes: PropTypes.object,
  toCredentialIdAttributes: PropTypes.object,
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  submitting: PropTypes.bool,
  switchTypeOptions: PropTypes.array,
};

SwitchCredentialFormSection.defaultProps = {
  orgOwnerIdAttributes: {},
  mappingFileAttributes: {},
  supportTicketAttributes: {},
  escalationTicketAttributes: {},
  emailIdsAttributes: {},
  descriptionAttributes: {},
  changeRequestTypeAttributes: {},
  fromCredentialIdAttributes: {},
  toCredentialIdAttributes: {},
  formData: {},
  onSubmit: () => {},
  resetForm: () => {},
  submitting: false,
  switchTypeOptions: [],
};
