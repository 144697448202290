import {
  ITBox,
  QboAlert,
  QboFormGroup,
  QboLabelGroup,
  QboModal,
  QboSpinnerProgress,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { EMAIL_STATUS } from '@pages/BackupStatusPage/pageHooks/constants/emailStatusConstants';
import { CONTACT } from '@pages/UserBackupPage/pageHooks/constants/contactConstants';
import { CALENDAR } from '@pages/UserBackupPage/pageHooks/constants/calendarConstants';
import { TASK } from '@pages/UserBackupPage/pageHooks/constants/taskConstants';
import { DRIVE_STATUS } from '@pages/BackupStatusPage/pageHooks/constants/driveStatusConstants';
import { EMAIL_ARCHIVE_STATUS } from '@pages/BackupStatusPage/pageHooks/constants/emailArchiveStatusConstants';
import { MS_CHAT_STATUS } from '@pages/BackupStatusPage/pageHooks/constants/msChatStatusConstants';
import { MS_CHAT } from '@pages/UserBackupPage/pageHooks/constants/msChatConstants';
import { DRIVE } from '@pages/UserBackupPage/pageHooks/constants/driveConstants';
import { EMAIL } from '@pages/UserBackupPage/pageHooks/constants/emailConstants';
import { MS_GROUP } from '@pages/UserBackupPage/pageHooks/constants/msGroupConstants';
import { SD_DRIVE } from '@pages/UserBackupPage/pageHooks/constants/sdDriveConstants';
import { SHAREPOINT } from '@pages/UserBackupPage/pageHooks/constants/sharepointConstants';
import useRetryBackupModal from './pageHooks/useRetryBackupModal';
import useCheckRetryBackup from './pageHooks/useCheckRetryBackup';

function getHourDiff(dateString) {
  const date = new Date(/^.*(Z|[+-]\d{2}:\d{2})$/.test(dateString) ? dateString : `${dateString}Z`);
  return ((Date.now() - date) / 3600000).toFixed(1);
}

export default function RetryBackupModal({ showModal, onClose, backup, backupType }) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState();

  const { checking, checkError, allowed, rules, resetCheckRetryBackup } = useCheckRetryBackup({
    showModal,
    backupId: backup.backupId,
    backupType,
  });

  const resetAndClose = () => {
    setErrorMessage(null);
    resetCheckRetryBackup();
    onClose();
  };

  const { fetching, form, handleSubmit } = useRetryBackupModal({
    backupId: backup.backupId,
    backupType,
    onSuccessRequest: resetAndClose,
    onFailedRequest: setErrorMessage,
  });

  if (showModal && checking) {
    return <QboSpinnerProgress className="DefaultApplication__not-ready-loading" overlay />;
  }

  return (
    <QboModal
      open={showModal}
      onClose={resetAndClose}
      title={t('retry_backup.title')}
      showCloseIcon
      preventOutsideClick>
      <ITBox direction="TransferSubscriptionFormPage__wrapper column">
        <QboFormGroup
          loadingButton={fetching}
          onSubmit={(e) => {
            setErrorMessage(null);
            handleSubmit(e);
          }}
          withGenerateAndResetButton
          buttonDisabled={!allowed}
          handleOnResetButton={resetAndClose}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit">
          {errorMessage && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <p>
                {t('remove_mailbox_from_exclusion.error', {
                  error: errorMessage,
                })}
              </p>
            </QboAlert>
          )}
          {allowed && !checkError && (
            <QboAlert
              variant="outlined"
              type="info"
              style={{
                border: '1px solid #ebebeb',
                background: '#f3f3f3',
                fontSize: '10px !important',
                marginTop: '20px',
              }}>
              <QboTypography
                noWrap={false}
                style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
                {t('retry_backup.confirmation')}
              </QboTypography>
            </QboAlert>
          )}
          {!allowed && !checkError && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <QboTypography
                noWrap={false}
                style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
                {rules && rules.length > 0 ? (
                  <>
                    <strong>{t('retry_backup.validations.not_allowed_with_rules')}</strong>
                    <ul>
                      {rules.map((rule) => (
                        <li>{rule}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <strong>{t('retry_backup.validations.not_allowed')}</strong>
                )}
              </QboTypography>
            </QboAlert>
          )}
          {checkError && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <QboTypography
                noWrap={false}
                style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
                <p>
                  <strong>{t('error')}</strong>
                </p>
                <p>
                  <strong>{checkError}</strong>
                </p>
              </QboTypography>
            </QboAlert>
          )}
          <ITBox
            className="TransferSubresellerPage__information-box"
            direction="column"
            sx={{ marginBottom: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QboLabelGroup title="Backup ID" description={backup.backupId || '-'} />
              </Grid>
              <Grid item xs={6}>
                <QboLabelGroup title="Backup Type" description={backupType || '-'} />
              </Grid>
            </Grid>

            {[EMAIL, EMAIL_ARCHIVE_STATUS, CONTACT, CALENDAR, TASK].includes(backupType) && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <QboLabelGroup
                    title="Current Message Numbers"
                    description={backup.currentMessagesCount || '0'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <QboLabelGroup
                    title="Total Message Numbers"
                    description={backup.totalMessagesCount || '0'}
                  />
                </Grid>
              </Grid>
            )}

            {[DRIVE, MS_GROUP, SD_DRIVE, SHAREPOINT].includes(backupType) && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <QboLabelGroup
                    title="Current File Numbers"
                    description={backup.currentFilesCount || '0'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <QboLabelGroup
                    title="Total File Numbers"
                    description={backup.totalFilesCount || '0'}
                  />
                </Grid>
              </Grid>
            )}

            {[MS_CHAT].includes(backupType) && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <QboLabelGroup
                    title="Current Chat Numbers"
                    description={backup.currentMessagesCount || '0'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <QboLabelGroup
                    title="Total Chat Numbers"
                    description={backup.totalMessagesCount || '0'}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QboLabelGroup
                  title="Backup Storage"
                  description={`${((backup.backupStorage || 0) / 1024).toFixed(2)} MB`}
                />
              </Grid>
              <Grid item xs={6}>
                <QboLabelGroup title="Retries" description={backup.retries || '0'} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QboLabelGroup title="Backup Status" description={backup.status} />
              </Grid>
              <Grid item xs={6}>
                <QboLabelGroup title="Backup Status Code" description={backup.statusCode} />
              </Grid>
            </Grid>

            <QboLabelGroup title="Additional Status" description={backup.additionalStatus || '-'} />
            <QboLabelGroup
              title="Last Updated At"
              description={
                `${backup.updatedAt} (${getHourDiff(backup.updatedAt)} hours ago)` || '-'
              }
            />
          </ITBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                placeholder="Support Ticket"
                label="Support Ticket"
                required
                fullWidth
                value={form.values.supportTicket}
                onChange={(e) => {
                  form.change('supportTicket', e.currentTarget.value);
                }}
                inputProps={{ maxLength: 225 }}
                errorMessage={form.errors.supportTicket}
                disabled={!allowed}
              />
            </Grid>
            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                value={form.values.escalationTicket}
                onChange={(e) => {
                  form.change('escalationTicket', e.currentTarget.value);
                }}
                inputProps={{ maxLength: 225 }}
                disabled={!allowed}
              />
            </Grid>
          </Grid>
          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            value={form.values.description}
            onChange={(e) => {
              form.change('description', e.currentTarget.value);
            }}
            inputProps={{ maxLength: 225 }}
            disabled={!allowed}
          />
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}
RetryBackupModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  backup: PropTypes.object,
  backupType: PropTypes.string,
};

RetryBackupModal.defaultProps = {
  showModal: false,
  onClose: () => {},
  backup: {},
  backupType: '',
};
