import { retentionTypeLabels } from './useRetentionPolicyService';

export default function useDataService() {
  const retentionTypeItemList = Object.keys(retentionTypeLabels).map((k) => ({
    id: Number.parseInt(k, 10),
    title: retentionTypeLabels[k],
    value: `${k}`,
  }));

  const columnNames = [
    { id: 1, columnName: 'id', label: 'Retention Policy ID', sx: { paddingLeft: 2 }, size: 100 },
    { id: 2, columnName: 'name', label: 'Retention Policy Name', sx: { paddingLeft: 2 } },
    {
      id: 3,
      columnName: 'retentionTypeLabel',
      label: 'Retention Type',
      sx: { paddingLeft: 2 },
    },
    { id: 4, columnName: 'period', label: 'Period', sx: { paddingLeft: 2 } },
    { id: 5, columnName: 'items', label: 'Items', sx: { paddingLeft: 2 } },
    { id: 6, columnName: 'accountId', label: 'Account ID', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 7,
      label: 'Organization Name',
      columnName: 'organizationName',
      size: 'sm',
    },
    {
      id: 8,
      label: 'Partner Name',
      columnName: 'partnerName',
      size: 'sm',
    },
    {
      id: 9,
      label: 'Parent Name',
      columnName: 'parentName',
      size: 'sm',
    },
    { id: 10, columnName: 'createdAt', label: 'Created At', sx: { paddingLeft: 2 } },
    { id: 11, columnName: 'updatedAt', label: 'Updated At', sx: { paddingLeft: 2 } },
  ];

  return {
    retentionTypeItemList,
    columnNames,
  };
}
