import {
  ITBox,
  QboAlert,
  QboFormGroup,
  QboLabelGroup,
  QboModal,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useRemoveMailboxFromExclusion from './pageHooks/useRemoveMailboxFromExclusion';

export default function RemoveMailboxFromExclusionModal({
  showModal,
  onFinish,
  onCancel,
  organizationOwnerId,
  email,
}) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState();
  const resetAndFinish = () => {
    setErrorMessage(null);
    onFinish();
  };
  const resetAndCancel = () => {
    setErrorMessage(null);
    onCancel();
  };
  const { fetching, form, handleSubmit } = useRemoveMailboxFromExclusion({
    organizationOwnerId,
    email,
    onSuccessRequest: resetAndFinish,
    onFailedRequest: setErrorMessage,
  });

  return (
    <QboModal
      open={showModal}
      onClose={resetAndCancel}
      title={t('remove_mailbox_from_exclusion.title')}
      showCloseIcon
      preventOutsideClick>
      <ITBox direction="TransferSubscriptionFormPage__wrapper column">
        <QboFormGroup
          loadingButton={fetching}
          onSubmit={(e) => {
            setErrorMessage(null);
            handleSubmit(e);
          }}
          withGenerateAndResetButton
          handleOnResetButton={resetAndCancel}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit">
          {errorMessage && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <p>
                {t('remove_mailbox_from_exclusion.error', {
                  error: errorMessage,
                })}
              </p>
            </QboAlert>
          )}
          <QboAlert
            variant="outlined"
            type="info"
            style={{
              border: '1px solid #ebebeb',
              background: '#f3f3f3',
              fontSize: '10px !important',
              marginTop: '20px',
            }}>
            <QboTypography
              noWrap={false}
              style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
              {t('remove_mailbox_from_exclusion.confirmation')}
            </QboTypography>
          </QboAlert>
          <ITBox
            className="TransferSubresellerPage__information-box"
            direction="column"
            sx={{ marginBottom: '20px' }}>
            <QboLabelGroup title="Organization Owner ID" description={organizationOwnerId || '-'} />
            <QboLabelGroup title="Email" description={email || '-'} />
          </ITBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                placeholder="Support Ticket"
                label="Support Ticket"
                required
                fullWidth
                value={form.values.supportTicket}
                onChange={(e) => {
                  form.change('supportTicket', e.currentTarget.value);
                }}
                inputProps={{ maxLength: 225 }}
                errorMessage={form.errors.supportTicket}
              />
            </Grid>
            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                value={form.values.escalationTicket}
                onChange={(e) => {
                  form.change('escalationTicket', e.currentTarget.value);
                }}
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
          </Grid>
          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            value={form.values.description}
            onChange={(e) => {
              form.change('description', e.currentTarget.value);
            }}
            inputProps={{ maxLength: 225 }}
          />
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}
RemoveMailboxFromExclusionModal.propTypes = {
  showModal: PropTypes.bool,
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
  organizationOwnerId: PropTypes.number,
  email: PropTypes.string,
};

RemoveMailboxFromExclusionModal.defaultProps = {
  showModal: false,
  onFinish: () => {},
  onCancel: () => {},
  organizationOwnerId: 0,
  email: '',
};
