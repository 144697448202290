import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { showFixedAlert } from '@features/application/applicationSlice';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import { useRecoverPurgeMailboxMutation } from '@services/WebApiService/RecoverPurgeMailboxService';
import useForm from './useForm';

export default function useRecoverPurgeMailbox({ selectedAccount, onSuccessRequest }) {
  const dispatch = useDispatch();
  const form = useForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [recoverPurgeMailboxMutation, { isLoading }] = useRecoverPurgeMailboxMutation();
  const [response, setResponse] = useState(null);
  const { t } = useTranslation();

  const submitRequest = async () => {
    try {
      const result = await recoverPurgeMailboxMutation({
        accessToken: accessTokenSet,
        parameters: {
          'support-ticket': form.attributes.supportTicketAttributes.value,
          description: form.attributes.descriptionAttributes.value,
          'escalation-ticket': form.attributes.escalationTicketAttributes.value,
          'user-backup-id': selectedAccount.id,
          'org-owner-id': selectedAccount.organizationId,
        },
      }).unwrap();
      setResponse(result);
    } catch (err) {
      setResponse({ error: err.data });
    }
  };

  const onValidateData = (e) => {
    form.onValidateData(e);
  };

  useEffect(() => {
    if (form.isValid) {
      submitRequest();
    }
  }, [form.isValid]);

  useEffect(() => {
    if (response?.['status-code'] === 200) {
      form.resetForm();
      if (response?.data?.serviceTypeCode) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': response?.data?.serviceTypeCode,
        });

        dispatch(
          showFixedAlert({
            descriptionHtml: t(
              'user_backup_page.recover_purge_mailbox.success_submitted_with_link',
              {
                link,
              }
            ),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('user_backup_page.recover_purge_mailbox.success_submitted'),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    } else if (response?.error) {
      dispatch(
        showFixedAlert({
          descriptionHtml: response?.error?.message,
          severity: 'error',
        })
      );
    }
  }, [response]);

  return {
    form,
    onValidateData,
    objectState: { fetching: isLoading },
  };
}
