import { constant } from '@config/BaseSetting';
import { organizationInformationPath, resellersListPath } from '@config/Routes/WebClientRoutes';
import useLocalStorage from '@hooks/useLocalStorage';
import { useGetRetentionPoliciesMutation } from '@services/WebApiService/RetentionPolicyApi';
import { useEffect, useMemo, useState } from 'react';
import { createSearchParams } from 'react-router-dom';

export const retentionTypeLabels = {
  1: 'All Email Accounts',
  2: 'Email Account',
  3: 'Department',
  4: 'Saved Search',
  5: 'Review Process',
  6: 'Message (from Review Process)',
  7: 'Site',
  8: 'Default Domain',
  9: 'All Site',
};

function formatPeriod(retentionPolicy) {
  const number = retentionPolicy.periodNumber;
  switch (retentionPolicy.periodUnit) {
    case 1:
      return `${number} day${number > 1 ? 's' : ''}`;
    case 2:
      return `${number} week${number > 1 ? 's' : ''}`;
    case 3:
      return `${number} month${number > 1 ? 's' : ''}`;
    case 4:
      return `${number} year${number > 1 ? 's' : ''}`;
    default:
      return `${number}`;
  }
}

export function formatList(retentionPolicies) {
  return retentionPolicies.map((retentionPolicy) => ({
    ...retentionPolicy,
    retentionTypeLabel: retentionTypeLabels[retentionPolicy.retentionType],
    period: retentionPolicy.periodNumber < 0 ? 'Unlimited' : formatPeriod(retentionPolicy),
    items: 'Show items',
  }));
}

const initialPagination = { pageIndex: 0, pageSize: 10 };

export default function useRetentionPolicyService({ organizationOwnerId }) {
  const [fetchFlag, setFetchFlag] = useState(true);
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [getRetentionPolicies, getRetentionPoliciesState] = useGetRetentionPoliciesMutation();
  const [pagination, setPagination] = useState(initialPagination);

  const [searchText, setSearchText] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [selectedReseller, setSelectedReseller] = useState();
  const [retentionType, setRetentionType] = useState();
  const [matchText, setMatchText] = useState();
  const [showItemRetentionPolicy, setShowItemRetentionPolicy] = useState(null);

  const getSearchId = () => {
    if (/^\d+$/.test(searchText)) {
      return parseInt(searchText, 10);
    }
    return 0;
  };

  const updateMatchText = () => {
    let currentMatchText = '';
    let first = true;
    const searchId = getSearchId();
    if (searchId) {
      currentMatchText += 'Matching';
      first = false;
      currentMatchText += ` ID = ${searchId}`;
    } else if (searchText) {
      if (first) {
        currentMatchText += 'Matching';
        first = false;
      }
      currentMatchText += ` Name LIKE %${searchText}%`;
    }
    if (selectedOrganization) {
      if (first) {
        currentMatchText += 'Matching';
        first = false;
      }
      currentMatchText += ` Organization Owner ID = ${selectedOrganization}`;
    }
    if (selectedReseller) {
      if (first) {
        currentMatchText += 'Matching';
        first = false;
      }
      currentMatchText += ` Partner ID = ${selectedReseller}`;
    }
    if (retentionType) {
      if (first) {
        currentMatchText += 'Matching';
        first = false;
      }
      currentMatchText += ` Retention Type = ${retentionTypeLabels[retentionType]}`;
    }

    setMatchText(currentMatchText);
  };

  const fetchRetentionPolicies = () => {
    updateMatchText();
    getRetentionPolicies({
      accessToken,
      organizationOwnerId: organizationOwnerId || selectedOrganization,
      resellerId: organizationOwnerId ? undefined : selectedReseller,
      pageNumber: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      filters: {
        search: searchText,
        retentionType,
      },
    });
  };

  useEffect(() => {
    if (fetchFlag) {
      if (!getRetentionPoliciesState.isLoading) {
        fetchRetentionPolicies();
      }
      setFetchFlag(false);
    }
  }, [fetchFlag]);

  const handleChangeSearchText = (e) => setSearchText(e.target.value);
  const handleChangeOrganization = (v) => setSelectedOrganization(v);
  const handleChangeReseller = (v) => setSelectedReseller(v);
  const handleChangeRetentionType = (e) => setRetentionType(e.target.value);

  const handleChangePagination = (newPagination) => {
    setPagination(newPagination);
    setFetchFlag(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPagination(initialPagination);
    setFetchFlag(true);
  };

  const handleReset = () => {
    setRetentionType(undefined);
    setSelectedOrganization(undefined);
    setSelectedReseller(undefined);
    setSearchText('');
    setPagination(initialPagination);
    setFetchFlag(true);
  };

  const paginationResult = useMemo(() => {
    if (!getRetentionPoliciesState.data?.pagination) return null;
    return JSON.parse(getRetentionPoliciesState.data.pagination);
  }, [getRetentionPoliciesState.data]);

  const clickableColumnList = [
    {
      id: 1,
      column: 'organizationName',
      path: (row) => {
        const queryParams = createSearchParams({
          id: row?.organizationOwnerId,
          name: row?.organizationName,
        }).toString();
        return [organizationInformationPath, queryParams];
      },
    },
    {
      id: 2,
      column: 'partnerName',
      path: (row) => {
        const queryParams = createSearchParams({
          id: row?.partnerId,
          name: row?.partnerName,
        }).toString();
        return [resellersListPath, queryParams];
      },
    },
    {
      id: 3,
      column: 'parentName',
      path: (row) => {
        const queryParams = createSearchParams({
          id: row?.parentId,
          name: row?.parentName,
        }).toString();
        return [resellersListPath, queryParams];
      },
    },
    {
      id: 4,
      column: 'items',
      handler: (row) => setShowItemRetentionPolicy(row),
    },
  ];

  const handleCloseShowItemModal = () => setShowItemRetentionPolicy(null);

  return {
    searchText,
    handleChangeSearchText,
    selectedOrganization,
    handleChangeOrganization,
    selectedReseller,
    handleChangeReseller,
    retentionType,
    handleChangeRetentionType,
    pagination,
    handleChangePagination,
    handleSubmit,
    handleReset,
    matchText,
    fetching: getRetentionPoliciesState.isLoading,
    retentionPolicies: getRetentionPoliciesState.data?.data ?? [],
    paginationResult,
    statusCode: getRetentionPoliciesState.error?.data?.['status-code'],
    error: getRetentionPoliciesState.error?.data?.message,
    clickableColumnList,
    showItemRetentionPolicy,
    handleCloseShowItemModal,
  };
}
