import { createAsyncThunk } from '@reduxjs/toolkit';
import { createExcludedMailboxesAPI } from '@services/WebApiService';

const createExcludedMailboxesAsync = createAsyncThunk(
  'Mailboxes/ExcludedMailboxes',
  async (paramsList) => {
    try {
      const data = await createExcludedMailboxesAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = createExcludedMailboxesAsync;

const createExcludedMailboxesExtraReducers = {
  [pending]: (state) => {
    state.excludedMailboxesState.fetching = true;
  },
  [rejected]: (state) => {
    state.excludedMailboxesState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.excludedMailboxesState.fetching = false;

    if (action?.payload?.data) {
      state.excludedMailboxesState.statusCode = action?.payload?.data['status-code'];
      state.excludedMailboxesState.mailboxesList = action?.payload?.data?.data;
      state.excludedMailboxesState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.excludedMailboxesState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.excludedMailboxesState.message = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.title) {
      state.excludedMailboxesState.statusCode = action?.payload?.data?.status;
      state.excludedMailboxesState.message = action?.payload?.data?.title;
    }

    if (action?.payload?.error) {
      state.excludedMailboxesState.error = action.payload.error;
    } else {
      state.excludedMailboxesState.success = true;
    }
    state.excludedMailboxesState.orgOwnerId = action.meta.arg?.parameters?.['org-owner-id'];
  },
};

export { createExcludedMailboxesAsync, createExcludedMailboxesExtraReducers };
