import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import WebApiRoutes from '@config/Routes/WebApiRoutes';
import WebApiGeneralRoutes from '@config/Routes/WebApiGeneralRoutes';

const baseQuery = fetchBaseQuery({
  baseUrl: WebApiRoutes.getBaseUrl(),
});

const RetentionPolicyApi = createApi({
  reducerPath: 'retentionPolicyApi',
  baseQuery,
  endpoints: (builder) => ({
    getRetentionPolicies: builder.mutation({
      query: ({ accessToken, organizationOwnerId, resellerId, pageNumber, pageSize, filters }) => {
        const searchById = /^\d+$/.test(filters.search) ? Number.parseInt(filters.search, 10) : 0;
        const fullTextSearch = searchById ? '' : filters.search;
        return {
          url: WebApiGeneralRoutes.apiV1Path(`/retention-policies`, {
            organizationOwnerId,
            resellerId,
            pageNumber,
            pageSize,
          }),
          method: 'POST',
          headers: { Authorization: `Bearer ${accessToken}` },
          body: {
            'search-by-id': searchById,
            'full-text-search': fullTextSearch,
            filters: [
              {
                field: 'filter_retentionType',
                value: filters.retentionType || '0',
              },
            ],
          },
        };
      },
    }),
    getRetentionPolicyItems: builder.mutation({
      query: ({ accessToken, retentionPolicyId, pageNumber, pageSize }) => ({
        url: WebApiGeneralRoutes.apiV1Path(`/retention-policy-items/${retentionPolicyId}`, {
          pageNumber,
          pageSize,
        }),
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    }),
  }),
});

export const { useGetRetentionPoliciesMutation, useGetRetentionPolicyItemsMutation } =
  RetentionPolicyApi;

export default RetentionPolicyApi;
