import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { ITBox, QboButton, QboCard, QboModal } from '@ui/Components';
import BaseSetting, { constant } from '@config/BaseSetting';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import QboAdvanceTable from '@ui/Components/QboAdvanceTable';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { useInitiateSdDomainListMutation } from '@services/WebApiService/SdDomainServices';
import useLocalStorage from '@hooks/useLocalStorage';
import SdDomainBackupsFormSection from '@ui/Sections/DomainBackupFormPage/SdDomainBackupsFormSection';
import useSdDomainBackups from './pageHooks/useSdDomainBackups';
import useForm from './pageHooks/useForm';

function SdDomainBackups() {
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    organizationOwnerIdAttributes,
    descriptionAttributes,
    domainAttributes,
    onSubmit,
    resetForm,
    formData,
    isValid,
    requestResponse,
    setRequestResponse,
    isRequesting,
  } = useForm();
  const { t } = useTranslation();
  const { getFilterdDataFromResponse } = useSdDomainBackups();
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });
  const [sdDomainBackupsListState, setSdDomainBackupsListState] = useState([]);
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const [showModal, setShowModal] = useState(false);
  const previousDomainBackupListData = useRef();

  // mutation
  const [initiateSdDomainList, initiateSdDomainListState] = useInitiateSdDomainListMutation();
  const { isLoading, rows, errorMessage, statusCode, responsePagination } = useMemo(
    () => ({
      isLoading: initiateSdDomainListState.isLoading,
      rows: initiateSdDomainListState.data?.data ?? [],
      errorMessage: initiateSdDomainListState.error?.data?.message,
      statusCode: initiateSdDomainListState.error?.status,
      responsePagination: JSON.parse(initiateSdDomainListState.data?.pagination || '{}'),
    }),
    [initiateSdDomainListState]
  );
  const [fetchFlag, setFetchFlag] = useState(true);

  useEffect(() => {
    previousDomainBackupListData.current = rows;
    getFilterdDataFromResponse(rows, setSdDomainBackupsListState);
  }, [rows]);

  useEffect(() => {
    setFetchFlag(true);
  }, [paginations.pageSize, paginations.pageIndex]);

  // fetch handler
  const fetch = useCallback(() => {
    initiateSdDomainList({
      accessToken: accessTokenSet,
      pageNumber: paginations.pageIndex + 1,
      pageSize: paginations.pageSize,
    });
  }, [accessTokenSet, paginations]);

  useEffect(() => {
    if (!fetchFlag) return;
    fetch();
    setFetchFlag(false);
  }, [fetchFlag, fetch]);

  return (
    <>
      <HeaderPageBlock>{t('sd_domain_backups.header.text')}</HeaderPageBlock>
      <QboCard noPadding isMainWrapper>
        <QboCard>
          <ITBox className="SystemStatusSection__wrapper" direction="column">
            <ITBox className="SystemStatusSection__create-button">
              <IconButton sx={{ position: 'relative', right: '0.7rem' }} onClick={() => fetch()}>
                <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
              </IconButton>
              &nbsp;
              <QboButton variant="contained" onClick={() => setShowModal(true)}>
                {t('mailbox_change.new_request')}
              </QboButton>
            </ITBox>
          </ITBox>

          {(rows.length > 0 || isLoading) && (
            <QboAdvanceTable
              rows={sdDomainBackupsListState}
              isLoading={isLoading || previousDomainBackupListData.current !== rows}
              pagination={paginations}
              setPagination={setPaginations}
              headers={BaseSetting.domainBackupsHeaders}
              enableSorting={Boolean(true)}
              rowsCount={responsePagination?.TotalCount}
            />
          )}
          {!rows?.length &&
            !isLoading &&
            (statusCode === 500 ? (
              <NoStatusSection
                forbiddenErrorMessage={{ heading: t('error'), caption: errorMessage }}
              />
            ) : (
              <NoStatusSection forbiddenErrorMessage={errorMessage} />
            ))}
        </QboCard>
        <QboModal
          onClose={() => {
            resetForm();
            setShowModal(false);
            fetch();
            setRequestResponse();
          }}
          open={showModal}
          title={t('sd_domain_backups.modal.title_text')}
          showCloseIcon
          preventOutsideClick>
          <SdDomainBackupsFormSection
            supportTicketAttributes={supportTicketAttributes}
            escalationTicketAttributes={escalationTicketAttributes}
            organizationOwnerIdAttributes={organizationOwnerIdAttributes}
            descriptionAttributes={descriptionAttributes}
            domainAttributes={domainAttributes}
            onSubmit={onSubmit}
            resetForm={resetForm}
            formData={formData}
            isValid={isValid}
            isRequesting={isRequesting}
            requestResponse={requestResponse}
          />
        </QboModal>
      </QboCard>
    </>
  );
}

export default SdDomainBackups;
