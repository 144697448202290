import {
  userBackupDetailPath,
  organizationInformationPath,
  resellersListPath,
} from '@config/Routes/WebClientRoutes';

export const MS_CHAT = 'ms_chat';
export const MS_CHAT_TEXT = 'MS Chat';
export const MS_CHAT_COLUMNS = [
  { label: 'ID', columnName: 'userBackupId' },
  {
    label: 'Email',
    columnName: 'email',
    size: 'sm',
    navigateURL: (row) =>
      `${userBackupDetailPath}?id=${row.userBackupId}&email=${row.email}&backupType=${MS_CHAT}`,
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
    navigateURL: (row) =>
      `${organizationInformationPath}?id=${row.organizationId}&name=${row.organizationName}`,
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
    navigateURL: (row) => `${resellersListPath}?id=${row.partnerId}&name=${row.partnerName}`,
  },
  {
    label: 'Parent Name',
    columnName: 'distributorName',
    size: 'sm',
    navigateURL: (row) =>
      `${resellersListPath}?id=${row.distributorId}&name=${row.distributorName}`,
  },
  { label: 'Protocol', columnName: 'protocol', size: 'sm' },
  { label: 'Tenant ID', columnName: 'tenantId', size: 'sm' },
  { label: 'Delta Link', columnName: 'deltaLink', size: 'sm' },
  { label: 'Last Backup At', columnName: 'lastBackupAt', size: 'sm' },
  { label: 'Last Backup Status', columnName: 'lastBackupStatus', size: 'sm' },
  {
    label: 'Last Notification Success Backup Sent At',
    columnName: 'lastBackupSuccessSentAt',
    size: 'sm',
  },
  { label: 'Next Backup At', columnName: 'nextBackupAt', size: 'sm' },
  { label: 'Total Number of Chat Threads', columnName: 'chatThreadsTotal', size: 'sm' },
  { label: 'Total Number of Chat Messages', columnName: 'chatMessagesTotal', size: 'sm' },
  { label: 'Attachment Sync Status', columnName: 'attachmentSyncStatus', size: 'sm' },
  { label: 'Last Attachment Sync At', columnName: 'lastAttachmentSyncAt', size: 'sm' },
  { label: 'Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
];
export const MS_CHAT_DETAIL_ROWS = {
  userBackupId: 'Chat Account ID',
  email: 'Email',
  organizationName: 'Organization Name',
  partnerName: 'Partner Name',
  distributorName: 'Parent Name',
  protocol: 'Protocol',
  tenantId: 'Tenant ID',
  deltaLink: 'Delta Link',
  lastBackupId: 'Last Backup ID',
  lastBackupAt: 'Last Backup At',
  lastBackupSuccessSentAt: 'Last Notification Success Backup Sent At',
  nextBackupAt: 'Next Backup At',
  chatThreadsTotal: 'Total Number of Chat Threads',
  chatMessagesTotal: 'Total Number of Chat Messages',
  attachmentSyncStatus: 'Attachment Sync Status',
  lastAttachmentSyncAt: 'Last Attachment Sync At',
  deactivatedSince: 'Deactivated Since',
  deactivatedBy: 'Deactivated By',
  createdAt: 'Created At',
  updatedAt: 'Last Data Updated At',
};
export const MS_CHAT_LAST_BACKUP_ROWS = {
  backupId: 'Last Chat Backup ID',
  userBackupId: 'Chat Account ID',
  startTime: 'Start Backup Time',
  endTime: 'End Backup Time',
  status: 'Backup Status',
  backupStorage: 'Backup Storage (in Byte)',
  totalChatCount: 'Total Number of Chat',
  currentChatCount: 'Current Number of Chat',
  retries: 'Retries Time',
  errorMessage: 'Error Message',
  machineIP: 'Run on Machine IP',
  createdAt: 'Created At',
  updatedAt: 'Last Updated At',
};
