import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import WebApiRoutes from '@config/Routes/WebApiRoutes';
import WebApiGeneralRoutes from '@config/Routes/WebApiGeneralRoutes';

const baseQuery = fetchBaseQuery({
  baseUrl: WebApiRoutes.getBaseUrl(),
});

const MailboxApi = createApi({
  reducerPath: 'mailboxApi',
  baseQuery,
  endpoints: (builder) => ({
    removeMailboxFromExclusion: builder.mutation({
      query: ({ accessToken, params }) => ({
        url: WebApiGeneralRoutes.apiV1Path('/remove-mailbox-from-exclusion'),
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}` },
        body: params,
      }),
    }),
  }),
});

export const { useRemoveMailboxFromExclusionMutation } = MailboxApi;

export default MailboxApi;
