import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  ITBox,
  QboAlert,
  QboCard,
  QboCardCaption,
  QboFormGroup,
  QboSimpleDataTable,
  QboTabs,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import NoStatus from '@images/generals/NoStatus.svg';
import { useSelector } from 'react-redux';
import useAutoDiscovery from '@pages/ViewAutoDiscovery/pageHooks/useAutoDiscovery';

const m365Header = [
  {
    id: 1,
    label: 'Domain',
    columnName: 'domain',
  },
  {
    id: 2,
    label: 'Autodiscover Mailbox Status',
    columnName: 'mailbox-status',
  },
  {
    id: 3,
    label: 'Autodiscover Sharepoint Status',
    columnName: 'site-status',
  },
  {
    id: 4,
    label: 'AD Sync User Enabled',
    columnName: 'ad-sync-status',
  },
  {
    id: 5,
    label: 'Backup Drive',
    columnName: 'backup-drive',
  },
  {
    id: 6,
    label: 'Backup Contact',
    columnName: 'backup-contact',
  },
  {
    id: 7,
    label: 'Backup Calendar',
    columnName: 'backup-calendar',
  },
  {
    id: 8,
    label: 'Backup Task',
    columnName: 'backup-task',
  },
];

const gwsHeader = [
  {
    id: 1,
    label: 'Domain',
    columnName: 'domain',
  },
  {
    id: 2,
    label: 'Autodiscover Mailbox Status',
    columnName: 'mailbox-status',
  },
  {
    id: 3,
    label: 'Autodiscover Sharedrive Status',
    columnName: 'site-status',
  },
  {
    id: 4,
    label: 'Backup Drive',
    columnName: 'backup-drive',
  },
];

function ViewAutoDiscoveryFormSection({ organisationIdAttributes, onSubmit }) {
  const { t } = useTranslation();
  const { helperToConvertString } = useAutoDiscovery();

  const { autoDiscoveryState } = useSelector((state) => state.autoDiscoveryForm);
  const { autoDiscoveryFormList, statusCode, message, fetching, orgOwnerId } = autoDiscoveryState;

  useEffect(() => {
    if (statusCode === 200 || statusCode === 403) {
      organisationIdAttributes.handleChange('');
    }
  }, [statusCode]);

  const [selectTab, setSelectTab] = useState(0);
  const tabs = ['M365', 'GWS'];
  const handleOnTabClick = (e, newValue) => {
    setSelectTab(newValue);
  };

  const tabsComponent = (tabsArray, selectTabState) => {
    if (tabsArray[selectTabState] === 'M365') {
      return (
        <QboSimpleDataTable
          classNameHeaderStyle="view_auto_discovery_header"
          minWidth={false}
          loading={fetching}
          rows={helperToConvertString(
            autoDiscoveryFormList.filter((h) => h['crendential-type'] === 'm365')
          )}
          className="view_excluded_mailbox"
          header={m365Header}
          onClickRow={(row) => {}}
        />
      );
    }
    if (tabsArray[selectTabState] === 'GWS') {
      return (
        <QboSimpleDataTable
          classNameHeaderStyle="view_auto_discovery_header"
          minWidth={false}
          loading={fetching}
          rows={helperToConvertString(
            autoDiscoveryFormList.filter((h) => h['crendential-type'] === 'gws')
          )}
          className="view_excluded_mailbox"
          header={gwsHeader}
          onClickRow={(row) => {}}
        />
      );
    }

    return null;
  };

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton>
        {(statusCode || message) && (
          <QboAlert
            type={
              statusCode === 200 && autoDiscoveryFormList && autoDiscoveryFormList?.length !== 0
                ? 'success'
                : 'error'
            }
            style={{ fontSize: '1.15em' }}>
            {message}
          </QboAlert>
        )}
        <QboTextField
          id="organisationID"
          required={Boolean(true)}
          placeholder={t('view_auto_discovery_page.form.organisation_id')}
          label={t('view_auto_discovery_page.form.organisation_id')}
          value={organisationIdAttributes.value}
          errorMessage={organisationIdAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            organisationIdAttributes.handleChange(e.currentTarget.value);
          }}
          fullWidth
        />
      </QboFormGroup>
      {autoDiscoveryFormList?.length > 0 ? (
        <QboCard noPadding isMainWrapper sx={{ bgColor: 'blue' }}>
          {orgOwnerId && (
            <QboTypography
              sx={{
                fontSize: 18,
                color: '#000000DE',
                fontWeight: 600,
              }}>
              Organization Owner Id: {orgOwnerId}
            </QboTypography>
          )}
          <QboTabs tabIndex={selectTab} title={tabs} onChange={handleOnTabClick} />

          {tabsComponent(tabs, selectTab)}
        </QboCard>
      ) : (
        statusCode &&
        message &&
        statusCode !== 403 && (
          <QboCardCaption
            sx={{ width: '100%', height: '220px', border: '1px solid black' }}
            imageUrl={NoStatus}
            heading={t('system_status_section.no_status_section.no_record_text')}
          />
        )
      )}
      <br />
    </ITBox>
  );
}

export default ViewAutoDiscoveryFormSection;

ViewAutoDiscoveryFormSection.propTypes = {
  organisationIdAttributes: PropTypes.object,
  onSubmit: PropTypes.func,
};

ViewAutoDiscoveryFormSection.defaultProps = {
  organisationIdAttributes: {},
  onSubmit: () => {},
};
