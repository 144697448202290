import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { QboAdvanceTable, QboModal, QboTypography } from '@ui/Components';
import { useGetRetentionPolicyItemsMutation } from '@services/WebApiService/RetentionPolicyApi';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import NoStatusSection from '../SystemStatusPage/NoStatusSection';
import {
  ALL_EMAIL_ACCOUNT,
  DEFAULT_DOMAIN,
  DEPARTMENT,
  DEPARTMENT_RETENTION_POLICY_ITEMS_HEADERS,
  EMAIL_ACCOUNT,
  EMAIL_RETENTION_POLICY_ITEMS_HEADERS,
  MESSAGE,
  MESSAGE_RETENTION_POLICY_ITEMS_HEADERS,
  RETENTION_POLICY_ITEMS_HEADERS,
} from './constants/retentionPolicyItemsConstants';

const initialPagination = { pageIndex: 0, pageSize: 10 };

export default function RetentionPolicyItemsModal(props) {
  const { retentionPolicy, onClose } = props;
  const { t } = useTranslation();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [pagination, setPagination] = useState(initialPagination);
  const [getRetentionPolicyItems, getRetentionPolicyItemsState] =
    useGetRetentionPolicyItemsMutation();

  useEffect(() => {
    setPagination(initialPagination);
  }, [retentionPolicy]);

  useEffect(() => {
    if (!retentionPolicy) getRetentionPolicyItemsState.reset();
    else {
      getRetentionPolicyItems({
        accessToken,
        retentionPolicyId: retentionPolicy.id,
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      });
    }
  }, [retentionPolicy, pagination]);

  const headers = useMemo(() => {
    if (!retentionPolicy) return [];
    if (
      [ALL_EMAIL_ACCOUNT, EMAIL_ACCOUNT, DEFAULT_DOMAIN].includes(retentionPolicy.retentionType)
    ) {
      return EMAIL_RETENTION_POLICY_ITEMS_HEADERS;
    }
    if (retentionPolicy.retentionType === MESSAGE) return MESSAGE_RETENTION_POLICY_ITEMS_HEADERS;
    if (retentionPolicy.retentionType === DEPARTMENT)
      return DEPARTMENT_RETENTION_POLICY_ITEMS_HEADERS;
    return RETENTION_POLICY_ITEMS_HEADERS;
  }, [retentionPolicy]);

  const paginationResult = useMemo(() => {
    if (!getRetentionPolicyItemsState.data?.pagination) return null;
    return JSON.parse(getRetentionPolicyItemsState.data?.pagination);
  }, [getRetentionPolicyItemsState.data]);

  return (
    <QboModal
      onClose={onClose}
      open={!!retentionPolicy}
      title="Retention Policy Items"
      showCloseIcon
      preventOutsideClick>
      {retentionPolicy && (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
          <QboTypography variant="h5" wrap sx={{ fontSize: 18 }}>
            {retentionPolicy.name}
          </QboTypography>
          <QboTypography variant="h5" sx={{ marginLeft: 1, fontSize: 16 }}>
            (ID: {retentionPolicy.id})
          </QboTypography>
        </div>
      )}
      {(getRetentionPolicyItemsState.data?.data?.length > 0 ||
        getRetentionPolicyItemsState.isLoading) && (
        <QboAdvanceTable
          isLoading={getRetentionPolicyItemsState.isLoading}
          enableSorting
          enableTopToolBar={false}
          headers={headers}
          rows={getRetentionPolicyItemsState.data?.data}
          pagination={pagination}
          setPagination={setPagination}
          rowsCount={paginationResult?.TotalCount || 0}
        />
      )}

      {!getRetentionPolicyItemsState.isUninitialized &&
        !getRetentionPolicyItemsState.data?.data?.length &&
        !getRetentionPolicyItemsState.isLoading &&
        (getRetentionPolicyItemsState.error?.data?.['status-code'] === 500 &&
        getRetentionPolicyItemsState.error?.data?.message ? (
          <NoStatusSection
            forbiddenErrorMessage={{
              heading: t('error'),
              caption: getRetentionPolicyItemsState.error?.data?.message,
            }}
          />
        ) : (
          <NoStatusSection />
        ))}
    </QboModal>
  );
}

RetentionPolicyItemsModal.propTypes = {
  retentionPolicy: PropTypes.object,
  onClose: PropTypes.func,
};

RetentionPolicyItemsModal.defaultProps = {
  retentionPolicy: null,
  onClose: () => {},
};
