import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import WebApiRoutes from '@config/Routes/WebApiRoutes';
import WebApiGeneralRoutes from '@config/Routes/WebApiGeneralRoutes';

const baseQuery = fetchBaseQuery({
  baseUrl: WebApiRoutes.getBaseUrl(),
});

const BackupApi = createApi({
  reducerPath: 'backupApi',
  baseQuery,
  endpoints: (builder) => ({
    retryBackup: builder.mutation({
      query: ({ accessToken, params }) => ({
        url: WebApiGeneralRoutes.apiV1Path('/retry-backup'),
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}` },
        body: params,
      }),
    }),
    checkRetryBackup: builder.mutation({
      query: ({ accessToken, params }) => ({
        url: WebApiGeneralRoutes.apiV1Path('/check-retry-backup'),
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}` },
        body: params,
      }),
    }),
  }),
});

export const { useRetryBackupMutation, useCheckRetryBackupMutation } = BackupApi;

export default BackupApi;
