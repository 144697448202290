export const ALL_EMAIL_ACCOUNT = 1;
export const EMAIL_ACCOUNT = 2;
export const DEPARTMENT = 3;
export const SAVED_SEARCH = 4;
export const REVIEW_PROCESS = 5;
export const MESSAGE = 6;
export const SITE = 7;
export const DEFAULT_DOMAIN = 8;
export const ALL_SITE = 9;

export const RETENTION_POLICY_ITEMS_HEADERS = [
  { id: 1, columnName: 'id', label: 'ID', sx: { paddingLeft: 2 }, size: 100 },
  { id: 2, columnName: 'name', label: 'Name', sx: { paddingLeft: 2 } },
];

export const EMAIL_RETENTION_POLICY_ITEMS_HEADERS = [
  { id: 1, columnName: 'id', label: 'ID', sx: { paddingLeft: 2 }, size: 100 },
  { id: 2, columnName: 'name', label: 'Email', sx: { paddingLeft: 2 } },
];

export const DEPARTMENT_RETENTION_POLICY_ITEMS_HEADERS = [
  { id: 1, columnName: 'id', label: 'ID', sx: { paddingLeft: 2 }, size: 100 },
  { id: 2, columnName: 'name', label: 'Email', sx: { paddingLeft: 2 } },
  { id: 3, columnName: 'department', label: 'Department', sx: { paddingLeft: 2 } },
];

export const MESSAGE_RETENTION_POLICY_ITEMS_HEADERS = [
  { id: 1, columnName: 'id', label: 'ID', sx: { paddingLeft: 2 } },
];
