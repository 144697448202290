import {
  userBackupDetailPath,
  organizationInformationPath,
  resellersListPath,
} from '@config/Routes/WebClientRoutes';

export const DRIVE = 'drive';
export const DRIVE_COLUMNS = [
  { label: 'ID', columnName: 'userBackupId' },
  {
    label: 'Email',
    columnName: 'email',
    size: 'sm',
    navigateURL: (row) =>
      `${userBackupDetailPath}?id=${row.userBackupId}&email=${row.email}&backupType=${DRIVE}`,
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
    navigateURL: (row) =>
      `${organizationInformationPath}?id=${row.organizationId}&name=${row.organizationName}`,
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
    navigateURL: (row) => `${resellersListPath}?id=${row.partnerId}&name=${row.partnerName}`,
  },
  {
    label: 'Parent Name',
    columnName: 'distributorName',
    size: 'sm',
    navigateURL: (row) =>
      `${resellersListPath}?id=${row.distributorId}&name=${row.distributorName}`,
  },
  { label: 'Protocol', columnName: 'protocol', size: 'sm' },
  { label: 'Tenant ID', columnName: 'tenantId', size: 'sm' },
  { label: 'Azure App Version', columnName: 'azureAppVersion', size: 'sm' },
  { label: 'Last Backup At', columnName: 'lastBackupAt', size: 'sm' },
  { label: 'Last Backup Status', columnName: 'lastBackupStatus', size: 'sm' },
  {
    label: 'Last Backup Successful At',
    columnName: 'lastBackupSuccessfulAt',
    size: 'sm',
  },
  { label: 'Next Backup At', columnName: 'nextBackupAt', size: 'sm' },
  { label: 'Number of files', columnName: 'fileCount', size: 'sm' },
  {
    label: 'Storage used on Latest Backup (in KB)',
    columnName: 'storageUsed',
    size: 'sm',
  },
  {
    label: 'Total Storage Used (in KB)',
    columnName: 'totalStorageUsed',
    size: 'sm',
  },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
  { label: 'Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Data Deleted At', columnName: 'dataDeletedAt', size: 'sm' },
  { label: 'Data Deletion Status', columnName: 'dataDeletionStatus', size: 'sm' },
];
export const DRIVE_DETAIL_ROWS = {
  userBackupId: 'Drive Account ID',
  email: 'Email',
  organizationName: 'Organization Name',
  partnerName: 'Partner Name',
  distributorName: 'Parent Name',
  protocol: 'Protocol',
  tenantId: 'Tenant ID',
  azureAppVersion: 'Azure App Version',
  deltaLink: 'Delta Link',
  lastBackupId: 'Last Backup ID',
  lastBackupAt: 'Last Backup At',
  lastBackupSuccessfulAt: 'Last Successful Backup At',
  nextBackupAt: 'Next Backup At',
  fileCount: 'Number of files',
  storageUsed: {
    title: 'Storage used on Latest Backup',
    sub: 'in KB - Reflecting to the data on Tenant',
  },
  totalStorageUsed: {
    title: 'Total Storage Used',
    sub: 'in KB - Reflecting the usage of s3',
  },
  deactivatedSince: 'Deactivated Since',
  deactivatedBy: 'Deactivated By',
  dataDeletedAt: 'Data Deleted At',
  dataDeletionStatus: 'Data Deleted Status',
  deletionErrorMessage: 'Deletion Error Message',
  createdAt: 'Created At',
  updatedAt: 'Last Data Updated At',
};
export const DRIVE_LAST_BACKUP_ROWS = {
  backupId: 'Last Drive Backup ID',
  userBackupId: 'Drive Account ID',
  startTime: 'Start Backup Time',
  endTime: 'End Backup Time',
  status: 'Backup Status',
  backupStorage: 'Backup Storage (in Byte)',
  currentFilesCount: 'Current File Numbers',
  totalFilesCount: 'Total File Numbers',
  retries: 'Retries Time',
  errorMessage: 'Error Message',
  machineIP: 'Run on Machine IP',
  createdAt: 'Created At',
  updatedAt: 'Last Updated At',
};
