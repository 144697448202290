import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { useCheckRetryBackupMutation } from '@services/WebApiService/BackupApi';
import { useEffect } from 'react';

export default function useCheckRetryBackup({ showModal, backupId, backupType }) {
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [checkRetryBackup, checkRetryBackupState] = useCheckRetryBackupMutation();

  useEffect(() => {
    if (!showModal) return;
    checkRetryBackup({
      accessToken: accessTokenSet,
      params: {
        'backup-id': backupId,
        'backup-type': backupType,
      },
    });
  }, [showModal, backupId, backupType]);

  return {
    checking: checkRetryBackupState.isUninitialized || checkRetryBackupState.isLoading,
    checkError: checkRetryBackupState.isError
      ? checkRetryBackupState.error?.data?.message ||
        checkRetryBackupState.error?.data?.['status-code'] ||
        checkRetryBackupState.error?.status ||
        (checkRetryBackupState.error.name === 'FetchError'
          ? 'Network error occurred'
          : 'Unknown error occurred')
      : null,
    allowed: checkRetryBackupState.data?.data?.allowed,
    rules: checkRetryBackupState.data?.data?.rules,
    resetCheckRetryBackup: checkRetryBackupState.reset,
  };
}
