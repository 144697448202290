import { useState } from 'react';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { useInitiateSdDomainRequestMutation } from '@services/WebApiService/SdDomainServices';

export default function useSdDomainBackups() {
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [initiateSdDomainRequestMutation, { isLoading }] = useInitiateSdDomainRequestMutation();
  const [requestResponse, setRequestResponse] = useState({});

  const getFilterdDataFromResponse = (data, setDomainBackupsListState) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        id: item?.id,
        'status-description': item['status-description'],
        description: payloadObj.description,
        domain: payloadObj.domain,
        'org-owner-id': payloadObj['org-owner-id'],
        'support-ticket': payloadObj['support-ticket'],
        'escalation-ticket': payloadObj['escalation-ticket'],
        'executed-on': item['executed-on'],
      };
      return modifiedPayloadObj;
    });
    return setDomainBackupsListState(modifiedData);
  };

  const handleCreateBackupsRequests = async (parameters) => {
    const result = await initiateSdDomainRequestMutation({
      accessToken: accessTokenSet,
      parameters,
    });

    if (result?.error) {
      setRequestResponse(result?.error);
    } else {
      setRequestResponse({ status: result?.meta?.response?.status || 200, data: result.data });
    }
  };

  return {
    getFilterdDataFromResponse,
    handleCreateBackupsRequests,
    requestResponse,
    setRequestResponse,
    isLoading,
  };
}
