import useFeatureFlag from '@hooks/useFeatureFlag';
import { MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function useViewExcludedMailbox({ onRemoveMailboxFromExclusion }) {
  const { t } = useTranslation();
  const { featureFlags, hasPermission } = useFeatureFlag();
  const columns = [
    {
      id: 1,
      label: 'Name',
      columnName: 'name',
      size: 'sm',
    },
    {
      id: 2,
      label: 'Email',
      columnName: 'email',
      size: 'sm',
    },
  ];

  const menuActions = (row, closeMenu) => {
    const menuItems = [];
    if (hasPermission(featureFlags.RemoveMailboxFromExclusion)) {
      menuItems.push(
        <MenuItem
          key={0}
          onClick={() => {
            onRemoveMailboxFromExclusion(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('remove_mailbox_from_exclusion.title')}
        </MenuItem>
      );
    }
    return menuItems;
  };

  return { columns, menuActions };
}
