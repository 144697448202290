import useFeatureFlag from '@hooks/useFeatureFlag';
import { MenuItem } from '@mui/material';
import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ENTRA_ID_STATUS } from './constants/entraidStatusConstants';

export default function useRetryBackup(backupType) {
  const { t } = useTranslation();
  const { hasPermission, featureFlags } = useFeatureFlag();
  const [retryBackupModalState, setRetryBackupModalState] = useState({});

  const handleCloseModal = () => setRetryBackupModalState({});

  const hasMenuActions = backupType !== ENTRA_ID_STATUS && hasPermission(featureFlags.RetryBackup);

  const handleRetryBackupClick = (row) => {
    setRetryBackupModalState({
      showModal: true,
      backup: row,
      backupType,
    });
  };

  const menuActions = (row, closeMenu) => {
    const menuItems = [];
    if (hasPermission(featureFlags.RetryBackup)) {
      menuItems.push(
        <MenuItem
          key={0}
          onClick={() => {
            handleRetryBackupClick(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('retry_backup.title')}
        </MenuItem>
      );
    }
    return menuItems;
  };

  return {
    retryBackupModalState,
    handleCloseModal,
    hasMenuActions,
    menuActions,
  };
}
