import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboSimpleDataTable,
  QboCard,
  QboAlert,
  QboPagination,
  QboCardListItem,
  QboTabs,
  QboFormGroup,
  QboModal,
  QboTypography,
  QboTextField,
} from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import PartnerFilterSection from '@ui/Sections/Shared/PartnerFilterSection';
import OrganizationFilterSection from '@ui/Sections/Shared/OrganizationFilterSection';
import QboSortByButton from '@ui/Components/QboSortByButton';
import { t } from 'i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  resetPartnerFilterState,
  resetOrganizationFilterState,
} from '@features/application/applicationSlice';
import { EMAIL, EMAIL_TEXT } from '@pages/UserBackupPage/pageHooks/constants/emailConstants';
import { CONTACT } from '@pages/UserBackupPage/pageHooks/constants/contactConstants';
import { CALENDAR } from '@pages/UserBackupPage/pageHooks/constants/calendarConstants';
import { TASK } from '@pages/UserBackupPage/pageHooks/constants/taskConstants';
import { SHAREPOINT } from '@pages/UserBackupPage/pageHooks/constants/sharepointConstants';
import {
  EMAIL_ARCHIVE,
  EMAIL_ARCHIVE_TEXT,
} from '@pages/UserBackupPage/pageHooks/constants/emailArchiveConstants';
import { DRIVE } from '@pages/UserBackupPage/pageHooks/constants/driveConstants';
import {
  MS_GROUP,
  MS_GROUP_TEXT,
} from '@pages/UserBackupPage/pageHooks/constants/msGroupConstants';
import {
  SD_DRIVE,
  SD_DRIVE_TEXT,
} from '@pages/UserBackupPage/pageHooks/constants/sdDriveConstants';
import { MS_CHAT, MS_CHAT_TEXT } from '@pages/UserBackupPage/pageHooks/constants/msChatConstants';
import {
  ENTRA_ID,
  ENTRA_ID_TEXT,
} from '@pages/UserBackupPage/pageHooks/constants/entraidConstants';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useDateTime from '@hooks/useDateTime';
import useUrlQuery from '@hooks/useUrlQuery';
import RetryBackupModal from '@ui/Sections/BackupStatusPage/RetryBackupModal';
import useBackupStatusFetching from './pageHooks/useBackupStatusFetching';
import {
  systemStatusItems,
  elapsedItems,
  buttonStyle,
} from './pageHooks/constants/emailStatusConstants';
import useRetryBackup from './pageHooks/useRetryBackup';

const hasTimeZoneRegex = /^.*(Z|[+-]\d{2}:\d{2})$/;

function getDefaultFilters({ query, resellerId, organizationOwnerId }) {
  let date = dayjs(query.period);
  if (!query.period || !date.isValid()) date = dayjs();

  let exactDate = dayjs(query.date);
  if (!query.date || !exactDate.isValid()) exactDate = null;

  const filters = {
    status: systemStatusItems.find((i) => i.value === query.status)?.value || '',
    elapsed: elapsedItems.find((i) => i.value === query.elapsed)?.value || '',
    startDate: exactDate
      ? exactDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss')
      : date.startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    endDate: exactDate
      ? exactDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss')
      : date.endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    date,
    exactDate,
    selectedPartnerId: resellerId,
    selectedOrganizationOwnerId: organizationOwnerId,
    selectedParentId: null,
  };

  return filters;
}

const PLACEHOLDERS = {
  [EMAIL_TEXT]: 'Backup ID/Email Account ID/Email',
  [EMAIL_ARCHIVE_TEXT]: 'Backup ID/Journal ID',
  [CONTACT]: 'Backup ID/Email Account ID/Email',
  [CALENDAR]: 'Backup ID/Email Account ID/Email',
  [TASK]: 'Backup ID/Email Account ID/Email',
  [DRIVE]: 'Backup ID/Email Account ID/Email',
  [SHAREPOINT]: 'Backup ID/Sharepoint Account ID/Site Name/Domain Name',
  [MS_GROUP_TEXT]: 'Backup ID/Domain ID/Group Account ID',
  [MS_CHAT_TEXT]: 'Backup ID/Email Account ID/Email',
  [SD_DRIVE_TEXT]: 'Backup ID/Domain ID/Shared Drive Account ID',
  [ENTRA_ID_TEXT]: 'Backup ID/Backup Account ID/Name',
  default: 'Backup ID/Email Account ID/Email',
};

export default function BackupStatusPage(props) {
  const { query, setQuery } = useUrlQuery();
  const { dateDistance, dateTimeNow } = useDateTime();
  const { resellerId, organizationOwnerId } = props;
  const { featureFlags, hasPermission } = useFeatureFlag();
  const dispatch = useDispatch();
  const { backupStatusListState } = useSelector((state) => state.backupStatusState);
  const {
    error,
    statusCode,
    fetching,
    backupStatusList,
    paginations,
    forbiddenErrorMessage,
    backupStatusListCorsErrorMessage,
  } = backupStatusListState;

  // Elapsed time
  const getElapsedTime = (item) => {
    if (!item.startTime) return '-';
    const start = hasTimeZoneRegex.test(item.startTime) ? item.startTime : `${item.startTime}Z`;
    const status = item.status?.toLowerCase();
    if (status === 'completed' || status === 'error') {
      if (!item.endTime) return '-';
      const end = hasTimeZoneRegex.test(item.endTime) ? item.endTime : `${item.endTime}Z`;
      return dateDistance(start, end, { separator: ' ' }) || '-';
    }
    return dateDistance(start, dateTimeNow(), { separator: ' ' }) || '-';
  };

  // Add calculated values to the list
  const calcList = useMemo(
    () =>
      backupStatusList?.map((item) => {
        return {
          ...item,
          elapsedTime: getElapsedTime(item),
        };
      }),
    [backupStatusList]
  );

  // fetching
  const defaultPagination = { pageNumber: 1, pageSize: 10 };
  const [paginationState, setPaginationState] = useState(defaultPagination);

  // filter
  const [onSearch, setOnSearch] = useState(false);
  const defaultFilter = useMemo(
    () => getDefaultFilters({ query, resellerId, organizationOwnerId }),
    [query, resellerId, organizationOwnerId]
  );
  const [searchText, setSearchText] = useState(query.search);
  const [filterState, setFilterState] = useState(defaultFilter);
  const resetFilters = useCallback(
    (q) => {
      setQuery(q);
      setFilterState(getDefaultFilters({ query, resellerId, organizationOwnerId }));
      setSearchText('');
    },
    [resellerId, organizationOwnerId]
  );

  // tabs
  const tabNames = [
    EMAIL_TEXT,
    EMAIL_ARCHIVE_TEXT,
    CONTACT,
    CALENDAR,
    TASK,
    DRIVE,
    SHAREPOINT,
    MS_GROUP_TEXT,
    MS_CHAT_TEXT,
    SD_DRIVE_TEXT,
    ...(hasPermission(featureFlags.EntraId) ? [ENTRA_ID_TEXT] : []),
  ];
  const selectTab = useMemo(() => {
    const tabIndex = parseInt(query.tab, 10);
    return Number.isNaN(tabIndex) || tabIndex < 0 || tabIndex >= tabNames.length ? 0 : tabIndex;
  }, [query.tab]);

  const backupType = useMemo(() => {
    const tabName = tabNames[selectTab];
    if (tabName === EMAIL_TEXT) {
      return EMAIL.toLowerCase();
    }
    if (tabName === EMAIL_ARCHIVE_TEXT) {
      return EMAIL_ARCHIVE.toLowerCase();
    }
    if (tabName === MS_GROUP_TEXT) {
      return MS_GROUP.toLowerCase();
    }
    if (tabName === MS_CHAT_TEXT) {
      return MS_CHAT.toLowerCase();
    }
    if (tabName === SD_DRIVE_TEXT) {
      return SD_DRIVE.toLowerCase();
    }
    if (tabName === ENTRA_ID_TEXT) {
      return ENTRA_ID.toLowerCase();
    }
    return tabName.toLowerCase();
  }, [selectTab]);

  const searchPlaceholderText = useMemo(() => {
    const tabName = tabNames[selectTab];
    return tabName ? PLACEHOLDERS[tabName] ?? PLACEHOLDERS.default : PLACEHOLDERS.default;
  }, [selectTab]);

  const handleOnTabClick = (e, newValue) => {
    resetFilters({
      tab: newValue ? newValue.toString() : undefined,
    });
    setPaginationState(defaultPagination);
  };

  const {
    handleFetchBackupStatusListAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
  } = useBackupStatusFetching(defaultFilter, setFilterState, defaultPagination, setPaginationState);

  const { retryBackupModalState, handleCloseModal, hasMenuActions, menuActions } =
    useRetryBackup(backupType);

  const fetchHandler = () => {
    setOnSearch(true);
    if (!fetching) {
      handleFetchBackupStatusListAsync(
        paginationState.pageNumber,
        paginationState.pageSize,
        filterState.selectedPartnerId,
        filterState.selectedOrganizationOwnerId,
        backupType,
        searchText,
        filterState
      );
      setOnSearch(false);
    }
  };

  const handleChangePage = (newPage) => {
    setPaginationState({ pageNumber: newPage, pageSize: paginationState.pageSize });
  };

  const handleChangePageSize = (newPageSize) => {
    setPaginationState({ pageNumber: 1, pageSize: newPageSize });
  };

  const handleFilterdChange = (value, key) => {
    const newFilter = { ...filterState, [key]: value };
    setFilterState(newFilter);
  };

  const handleOnSearchClick = async (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      period: filterState.date.format('YYYY-MM'),
      date: filterState.exactDate?.format('YYYY-MM-DD'),
      status: filterState.status,
      elapsed: filterState.elapsed,
      search: searchText,
    });
    setPaginationState(defaultPagination);
    setOnSearch(true);
  };

  const handleOnResetButtonClick = (e) => {
    resetFilters({ tab: query.tab });
    setPaginationState(defaultPagination);
    setOnSearch(true);
    dispatch(resetPartnerFilterState());
    dispatch(resetOrganizationFilterState());
  };

  const handleOnChangeDate = (newDate) => {
    const date = newDate;
    const startDate = newDate.startOf('month').format('YYYY-MM-DDTHH:mm:ss');
    const endDate = newDate.endOf('month').format('YYYY-MM-DDTHH:mm:ss');

    const newFilter = { ...filterState, date, startDate, endDate, exactDate: null };
    setFilterState(newFilter);
  };

  const handleOnChangeExactDate = (newDate) => {
    const exactDate = newDate;
    const startDate =
      newDate?.startOf('day').format('YYYY-MM-DDTHH:mm:ss') ??
      filterState.date.startOf('month').format('YYYY-MM-DDTHH:mm:ss');
    const endDate =
      newDate?.endOf('day').format('YYYY-MM-DDTHH:mm:ss') ??
      filterState.date.endOf('month').format('YYYY-MM-DDTHH:mm:ss');

    const newFilter = { ...filterState, startDate, endDate, exactDate };
    setFilterState(newFilter);
  };

  useEffect(() => {
    if (onSearch) {
      fetchHandler();
    }
  }, [onSearch]);

  useEffect(() => {
    fetchHandler();
  }, [paginationState.pageNumber, paginationState.pageSize]);

  useEffect(() => {
    setPaginationState(defaultPagination);
    fetchHandler();
  }, [backupType]);

  return (
    <>
      <HeaderPageBlock>Backup Status</HeaderPageBlock>
      {backupStatusListCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {backupStatusListCorsErrorMessage}
        </QboAlert>
      )}
      <QboTabs
        noPadding
        isMainWrappe
        tabIndex={selectTab}
        title={tabNames}
        onChange={handleOnTabClick}
        sx={{ textTransform: 'capitalize', marginTop: 2 }}
      />
      <QboCard isMainWrapper>
        <QboTextField
          id="fullTextSearch"
          value={searchText}
          isWidthSetExplicit
          placeholder={searchPlaceholderText}
          inputProps={{ maxLength: 225 }}
          sx={{ marginBottom: 2 }}
          onChange={(e) => {
            setSearchText(e.currentTarget.value);
          }}
        />
        {![tabNames.indexOf(EMAIL_ARCHIVE_TEXT)].includes(selectTab) && (
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Period"
                views={['year', 'month']}
                value={filterState.date}
                defaultValue={dayjs()}
                maxDate={dayjs()}
                minDate={dayjs().subtract(2, 'year')}
                onChange={handleOnChangeDate}
                sx={{ marginBottom: 2, marginRight: 2 }}
              />
              <DatePicker
                label="Date"
                views={['day']}
                slotProps={{
                  field: {
                    shouldRespectLeadingZeros: true,
                    clearable: true,
                  },
                }}
                value={filterState.exactDate}
                maxDate={filterState.date.endOf('month')}
                minDate={filterState.date.startOf('month')}
                onChange={handleOnChangeExactDate}
                sx={{ marginBottom: 2 }}
              />
            </LocalizationProvider>
          </div>
        )}
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        </LocalizationProvider> */}
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div>
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.status}
              buttonWidth={220}
              startAdornmentTextWidth={70}
              startAdornmentText="Status"
              handleChange={(e) => {
                handleFilterdChange(e.target.value, 'status');
              }}
              menuItemList={systemStatusItems}
            />
            <QboSortByButton
              defaultValue="Any"
              marginRightValue={2}
              value={filterState.elapsed}
              buttonWidth={220}
              startAdornmentTextWidth={180}
              startAdornmentText="Running time"
              handleChange={(e) => {
                handleFilterdChange(e.target.value, 'elapsed');
              }}
              menuItemList={elapsedItems}
            />
            {/* TODO: this only for example usage, will deleted it soon
              <DistributorFilterSection
              label="Distributor"
              inputSize={180}
              handleChange={(value) => {
                handleFilterdChange(parseInt(value, 10), 'selectedParentId');
              }}
            /> */}
            {!resellerId && !organizationOwnerId && (
              <PartnerFilterSection
                parentId={filterState.selectedParentId}
                label="Partners"
                inputSize={180}
                handleChange={(value) => {
                  handleFilterdChange(parseInt(value, 10), 'selectedPartnerId');
                }}
              />
            )}
            {!organizationOwnerId && (
              <OrganizationFilterSection
                partnerId={filterState.selectedPartnerId}
                label="Organizations"
                inputSize={180}
                handleChange={(value) => {
                  handleFilterdChange(value, 'selectedOrganizationOwnerId');
                }}
              />
            )}
          </div>
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <>
          {(calcList?.length || fetching) && (
            <QboSimpleDataTable
              sx={{ width: '100%' }}
              rows={calcList}
              header={tableColumns(backupType)}
              loading={fetching}
              menuActions={hasMenuActions ? menuActions : null}
            />
          )}
          {(backupStatusList?.length || fetching) && (
            <QboCardListItem
              justifyContent="right"
              alignItems="center"
              style={{ paddingRight: '1.5em' }}
              rightContent={
                <QboPagination
                  hasNextLink={paginations?.HasNext}
                  hasPrevLink={paginations?.HasPrevious}
                  onClickPrevLink={() => {
                    const newPageNumber = paginationState.pageNumber - 1;
                    handleChangePage(newPageNumber);
                  }}
                  onClickNextLink={() => {
                    const newPageNumber = paginationState.pageNumber + 1;
                    handleChangePage(newPageNumber);
                  }}
                  onChangePageNumber={handleChangePageSize}
                  pageNumber={paginationState.pageSize}
                  paginationLabel="Page size"
                  paginationPages={backupType === SHAREPOINT ? ['10', '20'] : []}
                />
              }
            />
          )}

          {!backupStatusList?.length &&
            !fetching &&
            (statusCode === 500 ? (
              <NoStatusSection forbiddenErrorMessage={{ heading: t('error'), caption: error }} />
            ) : (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            ))}

          <QboModal
            classNameStyle="Qbo_model_error_message"
            title={messageDialog.title}
            showCloseIcon
            open={showMesageDialog}
            onClose={() => setShowMesageDialog(false)}>
            <QboTypography wrap={!false}>{messageDialog.message}</QboTypography>
          </QboModal>

          <RetryBackupModal
            backup={retryBackupModalState.backup}
            backupType={retryBackupModalState.backupType}
            showModal={retryBackupModalState.showModal}
            onClose={handleCloseModal}
          />
        </>
      </QboCard>
    </>
  );
}

BackupStatusPage.propTypes = {
  resellerId: PropTypes.number,
  organizationOwnerId: PropTypes.number,
};

BackupStatusPage.defaultProps = {
  resellerId: null,
  organizationOwnerId: null,
};
