import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import {
  createExcludedMailboxesAsync,
  resetCreateExcludedMailboxesState,
} from '@features/excludedMailboxes/excludedMailboxesSlice';
import useFormValidation from './useFormValidation';

const initialRemoveMailboxFromExclusionState = {
  showModal: false,
  organizationOwnerId: 0,
  email: '',
};

function useForm() {
  const {
    organisationIdAttributes,
    emailAttributes,
    resetForm,
    formData,
    errors,
    validate,
    validateAll,
  } = useFormValidation();

  const [removeMailboxFromExclusionState, setRemoveMailboxFromExclusionState] = useState(
    initialRemoveMailboxFromExclusionState
  );
  const previousPropsRef = useRef(formData);
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [isValid, setIsValid] = useState(false);

  const submitTheForm = (isValidOnSubmit) => {
    if (isValidOnSubmit) {
      const parameters = {
        'org-owner-id': formData['organisation-id'],
        email: formData.email,
      };
      dispatch(
        createExcludedMailboxesAsync({
          accessToken: accessTokenSet,
          reset: true,
          parameters,
        })
      );
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const isValidOnSubmit = await validate(formData);
    setIsValid(isValidOnSubmit);
    const previousProps = previousPropsRef.current;
    if (!isEqual(previousProps, formData)) {
      dispatch(resetCreateExcludedMailboxesState());
      submitTheForm(isValidOnSubmit);
      previousPropsRef.current = formData;
    }
    setRemoveMailboxFromExclusionState({
      ...removeMailboxFromExclusionState,
      organizationOwnerId: Number.parseInt(formData['organisation-id'], 10),
    });
  };

  useEffect(() => {
    submitTheForm();
  }, [isValid]);

  const onRemoveExcludeMailboxClick = (row) => {
    setRemoveMailboxFromExclusionState({
      ...removeMailboxFromExclusionState,
      showModal: true,
      email: row.email,
    });
  };

  const onRemoveExcludeMailboxModalFinish = () =>
    setRemoveMailboxFromExclusionState(initialRemoveMailboxFromExclusionState);

  const onRemoveExcludeMailboxModalCancel = () =>
    setRemoveMailboxFromExclusionState({
      ...removeMailboxFromExclusionState,
      showModal: false,
    });

  return {
    organisationIdAttributes,
    emailAttributes,
    isValid,
    resetForm,
    previousPropsRef,
    formData,
    onSubmit,
    onRemoveExcludeMailboxClick,
    onRemoveExcludeMailboxModalFinish,
    onRemoveExcludeMailboxModalCancel,
    removeMailboxFromExclusionState,
  };
}

export default useForm;
