import WebApiRoutes from '@config/Routes/WebApiRoutes';
import WebApiGeneralRoutes from '@config/Routes/WebApiGeneralRoutes';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: WebApiRoutes.getBaseUrl(),
});

const RecoverPurgeMailboxApi = createApi({
  reducerPath: 'recoverPurgeMailboxApi',
  baseQuery,
  endpoints: (builder) => ({
    recoverPurgeMailbox: builder.mutation({
      query: ({ parameters, accessToken }) => ({
        url: WebApiGeneralRoutes.apiV1Path('/recover-purge-mailbox'),
        method: 'POST',
        body: parameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    }),
  }),
});

export const { useRecoverPurgeMailboxMutation } = RecoverPurgeMailboxApi;

export default RecoverPurgeMailboxApi;
