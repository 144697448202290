export default function useDataService() {
  const gwsUsersTenantHeaders = [
    { id: 1, label: 'GWS ID', columnName: 'id', sx: { paddingLeft: 2 } },
    {
      id: 2,
      label: 'Email',
      columnName: 'email',
      sx: { paddingLeft: 2 },
    },
    {
      id: 3,
      label: 'User Name',
      columnName: 'name',
      sx: { paddingLeft: 2 },
    },
    {
      id: 4,
      label: 'UPN',
      columnName: 'upn',
      sx: { paddingLeft: 2 },
    },
    {
      id: 5,
      label: 'Mailbox',
      columnName: 'mailbox',
      sx: { paddingLeft: 2 },
    },
    {
      id: 6,
      label: 'Email Status',
      columnName: 'active',
      sx: { paddingLeft: 2 },
    },
    {
      id: 6,
      label: 'Drive Status',
      columnName: 'driveStatus',
      sx: { paddingLeft: 2 },
    },
    {
      id: 7,
      label: 'Suspended',
      columnName: 'suspended',
      sx: { paddingLeft: 2 },
    },
    {
      id: 8,
      label: 'Archived',
      columnName: 'archived',
      sx: { paddingLeft: 2 },
    },
    { id: 9, label: 'Organization Unit', columnName: 'orgUnitPath', sx: { paddingLeft: 2 } },
    { id: 10, label: 'For Order Purpose', columnName: 'order', sx: { paddingLeft: 2 } },
  ];

  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const suspendedStatus = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];

  const archivedStatus = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];

  const activeStatus = [
    { id: 1, value: 'active', title: 'Active' },
    { id: 2, value: 'available', title: 'Available' },
    { id: 2, value: 'not_available', title: 'Not Available' },
  ];

  const filterStates = {
    advanceClick: false,
    textFieldValue: '',
    searchFieldValueText: '',
    searchFieldValueID: '',
    selectedSortColumn: '',
    matchText: '',
    resetClicked: false,
  };

  const initialFilterState = [
    { field: 'filter_suspended', value: '' },
    { field: 'filter_archived', value: '' },
    { field: 'filter_active', value: '' },
  ];

  return {
    gwsUsersTenantHeaders,
    buttonStyle,
    suspendedStatus,
    archivedStatus,
    activeStatus,
    filterStates,
    initialFilterState,
  };
}
