import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { QboCard, QboBreadcrumbs, QboTypography, QboTabs, QboLink } from '@ui/Components';
import { userBackupInfoPath } from '@config/Routes/WebClientRoutes';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import UserBackupDetailSection from '@ui/Sections/UserBackupPage/UserBackupDetailSection';
import LastBackupSection from '@ui/Sections/UserBackupPage/LastBackupSection';
import { useSelector } from 'react-redux';
import useUrlQuery from '@hooks/useUrlQuery';

export default function UserBackupDetailPage() {
  // navigation
  const navigate = useNavigate();
  const { query, setQuery } = useUrlQuery();

  // state
  const { detailPageState } = useSelector((state) => state.userBackupState);
  const { id, email, backupType } = useMemo(() => {
    const state = { ...detailPageState };
    if (!state?.id && query.id) state.id = query.id;
    if (!state?.email && query.email) state.email = query.email;
    if (!state?.backupType && query.backupType) state.backupType = query.backupType;
    return state;
  }, [detailPageState, query.id, query.email, query.backupType]);

  // tabs
  const tabNames = ['Details', 'Last Backup'];
  const selectTab = useMemo(() => {
    const i = parseInt(query.tab, 10);
    return Number.isNaN(i) || i >= tabNames.length ? 0 : i;
  }, [query.tab]);
  const handleOnTabClick = (e, newValue) => {
    setQuery({ ...query, tab: newValue || undefined });
  };

  // store last backup id on state
  const [lastBackupId, setLastBackupId] = useState(0);

  // redirect to list page if state is null
  useEffect(() => {
    if (!id) {
      navigate(userBackupInfoPath);
    }
  }, [id]);

  const tabComponent = (selectTabState) => {
    switch (selectTabState) {
      case 0:
        return (
          <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
            <UserBackupDetailSection
              userBackupId={id}
              backupType={backupType}
              setLastBackupId={setLastBackupId}
            />
          </QboCard>
        );
      case 1:
        return (
          <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
            <LastBackupSection lastBackupId={lastBackupId} backupType={backupType} />
          </QboCard>
        );
      default:
        return <NoStatusSection />;
    }
  };

  return (
    <>
      <HeaderPageBlock>Backup Detail ({backupType})</HeaderPageBlock>
      <QboBreadcrumbs sx={{ marginBottom: 2 }}>
        <QboLink
          onClick={() => {
            navigate(userBackupInfoPath);
          }}>
          Backups
        </QboLink>
        <QboTypography color="text.primary">Backup Detail ({backupType})</QboTypography>
      </QboBreadcrumbs>
      <QboCard isMainWrapper>
        {id && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
            <QboTypography variant="h5" wrap>
              {email}
            </QboTypography>
            <QboTypography variant="h5">(ID: {id})</QboTypography>
          </div>
        )}
        <div
          style={{
            overflowX: 'auto',
            marginRight: 5,
            display: 'flex',
            scrollbarWidth: 'thin',
            backgroundColor: '#c4e3f0',
          }}>
          <QboTabs tabIndex={selectTab} title={tabNames} onChange={handleOnTabClick} />
        </div>
        {tabComponent(selectTab)}
      </QboCard>
    </>
  );
}
