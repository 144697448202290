import React from 'react';
import PropTypes from 'prop-types';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboAdvanceTable,
  QboAlert,
  QboCard,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import { useTranslation } from 'react-i18next';
import DistributorFilterSection from '@ui/Sections/Shared/DistributorFilterSection';
import OrganizationFilterSection from '@ui/Sections/Shared/OrganizationFilterSection';
import QboSortByButton from '@ui/Components/QboSortByButton';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import RetentionPolicyItemsModal from '@ui/Sections/RetentionPolicyPage/RetentionPolicyItemsModal';
import useRetentionPolicyService, { formatList } from './pageHooks/useRetentionPolicyService';
import useDataService from './pageHooks/useDataService';

export default function RetentionPolicyPage(props) {
  const { organizationOwnerId } = props;
  const { t } = useTranslation();
  const { retentionTypeItemList, columnNames } = useDataService();
  const {
    searchText,
    handleChangeSearchText,
    selectedOrganization,
    handleChangeOrganization,
    selectedReseller,
    handleChangeReseller,
    retentionType,
    handleChangeRetentionType,
    pagination,
    handleChangePagination,
    handleSubmit,
    handleReset,
    matchText,
    fetching,
    paginationResult,
    retentionPolicies,
    statusCode,
    error,
    clickableColumnList,
    showItemRetentionPolicy,
    handleCloseShowItemModal,
  } = useRetentionPolicyService({ organizationOwnerId });

  return (
    <>
      {!organizationOwnerId && <HeaderPageBlock>Retention Policy</HeaderPageBlock>}
      <QboCard>
        <QboCard isMainWrapper>
          <QboFormGroup
            onSubmit={handleSubmit}
            buttonPostion="start"
            handleOnResetButton={handleReset}
            firstButtonLabel="Search"
            withGenerateAndResetButton
            loadingButton={fetching}>
            <QboAlert
              variant="outlined"
              type="info"
              style={{
                border: '1px solid #ebebeb',
                background: '#f3f3f3',
                fontSize: '10px !important',
              }}>
              <QboTypography
                noWrap={false}
                style={{
                  fontSize: '13px',
                  color: '#767676',
                  whiteSpace: 'pre-line',
                }}>
                <strong>{t('retention_policy.information')}</strong>
              </QboTypography>
            </QboAlert>
            <QboTextField
              id="searchText"
              value={searchText}
              isWidthSetExplicit
              placeholder="Retention Policy ID/Retention Policy Name"
              inputProps={{ maxLength: 225 }}
              onChange={handleChangeSearchText}
            />
            <div style={{ display: 'flex' }}>
              <QboSortByButton
                defaultValue="All"
                value={retentionType}
                buttonWidth={220}
                startAdornmentTextWidth={220}
                startAdornmentText="Retention Type:"
                handleChange={handleChangeRetentionType}
                menuItemList={retentionTypeItemList}
                marginRightValue={2}
              />

              {!organizationOwnerId && (
                <>
                  <DistributorFilterSection
                    value={selectedReseller}
                    handleChange={handleChangeReseller}
                    label="Partner:"
                  />
                  <OrganizationFilterSection
                    value={selectedOrganization}
                    handleChange={handleChangeOrganization}
                    label="Organization:"
                  />
                </>
              )}
            </div>
          </QboFormGroup>
        </QboCard>
        {retentionPolicies.length > 0 && !fetching && (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
            }}>
            {matchText}
          </QboTypography>
        )}
        {(retentionPolicies.length > 0 || fetching) && (
          <QboAdvanceTable
            isLoading={fetching}
            enableSorting
            enableTopToolBar={false}
            headers={columnNames}
            rows={formatList(retentionPolicies)}
            pagination={pagination}
            setPagination={handleChangePagination}
            rowsCount={paginationResult?.TotalCount || 0}
            columnsClickableList={clickableColumnList}
            enableColumnClickable
          />
        )}
        {!retentionPolicies?.length &&
          !fetching &&
          (statusCode === 500 && error ? (
            <NoStatusSection forbiddenErrorMessage={{ heading: t('error'), caption: error }} />
          ) : (
            <NoStatusSection />
          ))}
      </QboCard>
      <RetentionPolicyItemsModal
        retentionPolicy={showItemRetentionPolicy}
        onClose={handleCloseShowItemModal}
      />
    </>
  );
}

RetentionPolicyPage.propTypes = {
  organizationOwnerId: PropTypes.number,
};

RetentionPolicyPage.defaultProps = {
  organizationOwnerId: null,
};
